import Images from "@Theme/Images";
import Styles from "./styles.module.scss";
import { useEffect, useMemo, useState } from "react";
import Icon from "@Atom/Icon";
import { validateEmail } from "@Helpers/validateEmail";
import { useLocation, useNavigate } from "react-router-dom";
import useQuery from "@Hooks/useQuery";
import OTPComponent from "@Molecule/OTPInput";
import {
  userGoogleLogin,
  userLogin,
  userRegister,
  userVerify,
} from "@Services/home";
import Spinner from "@Atom/Spinner";

import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { handleSubmit as handleSubmitLinkJobPost } from "@Helpers/getLinkJobPosting";
import useWindowSize from "@Hooks/useWindowSize";

export default function RegisterUser({ login = false }) {
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [validate, setValidate] = useState();
  const [otpAlert, setOtpAlert] = useState(null);
  const [otp, setOtp] = useState("");
  const query = useQuery();

  const { width } = useWindowSize();

  const [otpTimer, setOtpTimer] = useState(0);
  const [circularValue, setCircularValue] = useState(100);

  const [queryPhoneNumber, setQueryPhoneNumber] = useState(
    query?.get("p") || ""
  );
  const [queryHash, setQueryHash] = useState(query?.get("h") || "");
  const [queryEmail, setQueryEmail] = useState(query?.get("e") || "");
  const [queryName, setQueryName] = useState(query?.get("n") || "");

  const navigate = useNavigate();

  const { pathname, state } = useLocation();

  useEffect(() => {
    navigate(
      `${pathname}?h=${queryHash || ""}&p=${queryPhoneNumber || ""}&e=${
        queryEmail || ""
      }&n=${queryName || ""}`,
      {
        state: {
          ...state, // Menyimpan state sebelumnya
        },
      }
    );
  }, [
    navigate,
    pathname,
    queryHash,
    queryPhoneNumber,
    queryEmail,
    queryName,
    // state,
  ]);

  const [isLoadingRegist, setIsLoadingRegist] = useState(false);
  const [alert, setAlert] = useState("");

  const handleSubmit = async (e) => {
    if (login) {
      try {
        e.preventDefault();
        setIsLoadingRegist(true);
        const containsLetters = /[a-zA-Z]/.test(phoneNumber);
        if (
          !phoneNumber ||
          (phoneNumber && containsLetters && !validateEmail(phoneNumber))
        ) {
          setValidate(true);
          setIsLoadingRegist(false);
          setAlert("");
        } else {
          const { response } = await userLogin({
            emailOrPhoneNUmber: containsLetters
              ? phoneNumber
              : "62" + phoneNumber,
          });
          setValidate(false);
          setQueryHash(response);
          setQueryPhoneNumber(
            !validateEmail(phoneNumber) ? "62" + phoneNumber : phoneNumber
          );
          setIsLoadingRegist(false);
          setAlert("");
          setAlertVerify("");
          // setQueryType("OTP");
        }
      } catch (err) {
        setIsLoadingRegist(false);
        console.log(err);
        setAlert(err.response?.data?.error);
      }
    } else {
      try {
        e.preventDefault();
        setIsLoadingRegist(true);
        if (
          !name ||
          !phoneNumber ||
          !email ||
          (email && !validateEmail(email))
        ) {
          setValidate(true);
          setIsLoadingRegist(false);
          setAlert("");
        } else {
          const { response } = await userRegister({
            name: name || "",
            email: email || "",
            phoneNumber: "62" + phoneNumber,
            type: "phoneNumber",
          });
          setValidate(false);
          setQueryHash(response);
          setQueryPhoneNumber("62" + phoneNumber);
          setQueryEmail(email);
          setQueryName(name);
          setIsLoadingRegist(false);
          setAlert("");
          setAlertVerify("");
          // setQueryType("OTP");
        }
      } catch (err) {
        setIsLoadingRegist(false);
        console.log(err);
        setAlert(err.response?.data?.error);
      }
    }
  };

  const filler = useMemo(() => {
    const arr = [];
    for (let i = 3; i < queryPhoneNumber?.length - 3; i++) {
      arr.push("&#x2022;");
    }
    return arr;
  }, [queryPhoneNumber]);

  useEffect(() => {
    setOtpTimer(60);
  }, [queryHash]);

  const secondsToTime = (secs) => {
    // var hours = Math.floor(secs / (60 * 60));
    var divisor_for_minutes = secs % (60 * 60);
    var minutes = Math.floor(divisor_for_minutes / 60);
    var divisor_for_seconds = divisor_for_minutes % 60;
    var seconds = Math.ceil(divisor_for_seconds);
    if (minutes < 10) {
      minutes = `0${minutes}`;
    }
    if (seconds < 10) {
      seconds = `0${seconds}`;
    }
    return minutes + ":" + seconds;
  };

  useEffect(() => {
    let otpInterval;
    otpInterval = setInterval(() => {
      setOtpTimer((prev) => (prev > 0 ? prev - 1 : prev));
    }, 1000);
    return () => clearInterval(otpInterval);
  }, []);

  useEffect(() => {
    setCircularValue((otpTimer / 60) * 100);

    // console.log(otpTimer, "gggg");
  }, [otpTimer]);

  const [loadingResend, setLoadingResend] = useState(false);

  const handleResendOtp = async (event, type) => {
    try {
      event.preventDefault();
      setLoadingResend(true);

      const { response } = login
        ? await userLogin({
            emailOrPhoneNUmber: queryPhoneNumber || query?.get("p") || "",
            type: type,
          })
        : await userRegister({
            name: queryName || query?.get("n") || "",
            email: queryEmail || query.get("e") || "",
            phoneNumber: queryPhoneNumber,
            type: type,
          });

      setLoadingResend(false);
      setAlertVerify("");
      setQueryHash(response);
      setOtpTimer(60);
    } catch (error) {
      setLoadingResend(false);
      setAlertVerify(error.response?.data?.error);
      console.log(error);
      //   setOnResendOtp(false);
    }
  };

  // console.log(state, "test");

  const [isLoadingVerify, setIsLoadingVerify] = useState(false);
  const [alertVerify, setAlertVerify] = useState("");

  const [isLoadingLinkJobPost, setIsLoadingLinkJobPost] = useState(null);
  const [errorLinkJobPost, setErrorLinkJobPost] = useState(null);

  const handleSendOtp = async (event) => {
    try {
      setIsLoadingVerify(true);
      event.preventDefault();

      if (!otp) {
        setOtpAlert("Otp belum diisi");
        setIsLoadingVerify(false);
        setAlertVerify("Otp belum diisi");
      } else {
        const { response } = await userVerify({
          hash: queryHash || query?.get("h") || "",
          otp: otp || "",
          type: login ? "login" : "register",
        });

        setOtpAlert(null);
        setIsLoadingVerify(false);
        setAlertVerify("");

        const isProfileIncomplete =
          !response?.user?.idNumber &&
          !response?.user?.gender &&
          !response?.user?.domicile &&
          !response?.user?.dateOfBirth &&
          !response?.user?.address;

        if (isProfileIncomplete) {
          navigate(`/user/update-profile`);
          if (state && state?.data && response?.accessToken) {
            sessionStorage?.setItem(
              "stateJobPostLinkLogin",
              JSON.stringify(state)
            );
          }
          // Simpan ke localStorage setelah handleSubmit selesai
          localStorage.setItem(
            "accessTokenUserLanding",
            response?.accessToken || ""
          );
          localStorage.setItem("nameUserLanding", response?.user?.name || "");
          localStorage.setItem(
            "phoneNumberUserLanding",
            response?.user?.phoneNumber || ""
          );
          localStorage.setItem("emailUserLanding", response?.user?.email || "");
          localStorage.setItem(
            "profilePictUserLanding",
            response?.user?.profilePic || ""
          );
          localStorage.setItem(
            "dataProfile",
            isProfileIncomplete ? "inComplete" : "complete"
          );
        } else {
          if (state && state?.data && response?.accessToken) {
            setIsLoadingLinkJobPost(true);

            await handleSubmitLinkJobPost(
              new Event("submit", { bubbles: true, cancelable: true }),
              state?.data?.location,
              state?.data?.id,
              setIsLoadingLinkJobPost,
              setErrorLinkJobPost,
              width,
              true
            );

            // Simpan ke localStorage setelah handleSubmit selesai

            navigate(`/`); // Navigasi ke home
          } else {
            navigate(`/`); // Navigasi langsung jika tidak ada state atau accessToken
          }

          // Simpan ke localStorage setelah handleSubmit selesai
          localStorage.setItem(
            "accessTokenUserLanding",
            response?.accessToken || ""
          );
          localStorage.setItem("nameUserLanding", response?.user?.name || "");
          localStorage.setItem(
            "phoneNumberUserLanding",
            response?.user?.phoneNumber || ""
          );
          localStorage.setItem("emailUserLanding", response?.user?.email || "");
          localStorage.setItem(
            "profilePictUserLanding",
            response?.user?.profilePic || ""
          );
          localStorage.setItem(
            "dataProfile",
            isProfileIncomplete ? "inComplete" : "complete"
          );
        }
      }
    } catch (error) {
      console.log(error);
      setIsLoadingVerify(false);
      setAlertVerify(error.response?.data?.error);
    }
  };

  const handleSuccess = async (response) => {
    try {
      setIsLoadingVerify(true);

      const { response: responseData } = await userGoogleLogin(
        response.credential
      );

      setIsLoadingVerify(false);
      setAlertVerify("");

      if (
        !responseData?.user?.idNumber &&
        !responseData?.user?.gender &&
        !responseData?.user?.domicile &&
        !responseData?.user?.dateOfBirth &&
        !responseData?.user?.address
      ) {
        navigate(`/user/update-profile?id=${responseData?.user?.id}`);

        if (state && state?.data && responseData?.accessToken) {
          sessionStorage?.setItem(
            "stateJobPostLinkLogin",
            JSON.stringify(state)
          );
        }
        localStorage?.setItem(
          "accessTokenUserLanding",
          responseData?.accessToken || ""
        );
        localStorage?.setItem(
          "nameUserLanding",
          responseData?.user?.name || ""
        );
        localStorage?.setItem(
          "phoneNumberUserLanding",
          responseData?.user?.phoneNumber || ""
        );
        localStorage?.setItem(
          "emailUserLanding",
          responseData?.user?.email || ""
        );
        localStorage?.setItem(
          "profilePictUserLanding",
          responseData?.user?.profilePic || ""
        );
        localStorage?.setItem(
          "dataProfile",
          !responseData?.user?.idNumber &&
            !responseData?.user?.gender &&
            !responseData?.user?.domicile &&
            !responseData?.user?.dateOfBirth &&
            !responseData?.user?.address
            ? "inComplete"
            : "complete"
        );
      } else {
        if (state && state?.data && responseData?.accessToken) {
          setIsLoadingLinkJobPost(true);

          await handleSubmitLinkJobPost(
            new Event("submit", { bubbles: true, cancelable: true }),
            state?.data?.location,
            state?.data?.id,
            setIsLoadingLinkJobPost,
            setErrorLinkJobPost,
            width,
            true
          );

          navigate(`/`); // Navigasi ke home
        } else {
          navigate(`/`);
        }

        localStorage?.setItem(
          "accessTokenUserLanding",
          responseData?.accessToken || ""
        );
        localStorage?.setItem(
          "nameUserLanding",
          responseData?.user?.name || ""
        );
        localStorage?.setItem(
          "phoneNumberUserLanding",
          responseData?.user?.phoneNumber || ""
        );
        localStorage?.setItem(
          "emailUserLanding",
          responseData?.user?.email || ""
        );
        localStorage?.setItem(
          "profilePictUserLanding",
          responseData?.user?.profilePic || ""
        );
        localStorage?.setItem(
          "dataProfile",
          !responseData?.user?.idNumber &&
            !responseData?.user?.gender &&
            !responseData?.user?.domicile &&
            !responseData?.user?.dateOfBirth &&
            !responseData?.user?.address
            ? "inComplete"
            : "complete"
        );
      }
    } catch (error) {
      console.log(error);
      setAlertVerify(error.response?.data?.error);
      setIsLoadingVerify(false);
    }
  };

  const handleError = () => {
    console.error("Login Failed");
  };

  return (
    <div className={Styles.container}>
      {/* <div className={Styles.circle}></div> */}

      <div className={Styles.content}>
        <div className={Styles.imagesJobs}>
          <img src={Images.LANDING_LOGIN_USER_IMG} alt="" />
        </div>

        <div className={Styles.form}>
          <div className={Styles.images365}>
            <img src={Images.KERJA365_LIGHT} alt="" />
          </div>

          <div className={Styles.titleBox}>
            {query?.get("h") && (
              <div
                className={Styles.back}
                onClick={() => {
                  setQueryHash("");
                  setQueryPhoneNumber("");
                  setQueryEmail("");
                  setQueryName("");
                  //   setQueryType("");
                }}
              >
                <Icon icon={"arrow-left-back"} size={20} color={"#000000"} />
                <span>Kembali</span>
              </div>
            )}
            <span className={Styles.H1}>
              {query?.get("h")
                ? "Konfirmasi OTP"
                : login
                ? "Masuk Akun"
                : "Daftar Akun"}
            </span>

            {query?.get("h") ? (
              <span>
                {login && validateEmail(queryPhoneNumber)
                  ? "Silakan masukan kode OTP yang telah kami kirim ke Email "
                  : "Silakan masukan kode OTP yang telah kami kirim ke nomor Whatsapp +"}
                {queryPhoneNumber?.slice(0, 3)}
                {filler?.map((each, i) => {
                  return <span key={i}>&#x2022;</span>;
                })}
                {queryPhoneNumber?.slice(
                  queryPhoneNumber?.length - 3,
                  queryPhoneNumber?.length
                )}
              </span>
            ) : login ? (
              <span>👋🏻Selamat datang kembali!</span>
            ) : (
              <span>Silahkan daftarkan akunmu sekarang!</span>
            )}
          </div>

          {!query?.get("h") && (
            <GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_ID}>
              <div className={Styles.googleLogin}>
                {isLoadingVerify || isLoadingLinkJobPost ? (
                  <button disabled>
                    <Spinner />
                  </button>
                ) : (
                  <GoogleLogin
                    onSuccess={handleSuccess}
                    onError={handleError}
                    text={!login ? "continue_with" : "sign_in_with"}
                    width="100%"
                  />
                )}
              </div>
            </GoogleOAuthProvider>
          )}

          {!query?.get("h") && (
            <div className={Styles.choose}>
              <div></div>
              <span>
                {login
                  ? "Atau Masuk Menggunakan"
                  : "Atau Daftarkan Data Diri Kamu"}
              </span>
              <div></div>
            </div>
          )}

          {query?.get("h") ? (
            <form
              onSubmit={
                isLoadingVerify || isLoadingLinkJobPost ? null : handleSendOtp
              }
              className={`${Styles.list} ${query?.get("h") && Styles.listOtp}`}
            >
              <div className={Styles.formOtp}>
                <OTPComponent
                  onChange={(value) => {
                    setOtp(value);
                    setOtpAlert(null);
                  }}
                  alert={otpAlert}
                />
              </div>

              <div className={Styles.buttonWrapper}>
                <div className={Styles.resendWrapper}>
                  <div className={Styles.resendController}>
                    <button
                      type="button"
                      disabled={+otpTimer !== 0 || loadingResend}
                      onClick={(e) => {
                        if (+otpTimer === 0) {
                          handleResendOtp(e, "phoneNumber");
                        }
                      }}
                      className={Styles.ableResend}
                    >
                      {"Kirim ulang OTP ke WhatsApp"}
                    </button>
                    <button
                      type="button"
                      disabled={+otpTimer !== 0 || loadingResend}
                      onClick={(e) => {
                        if (+otpTimer === 0) {
                          handleResendOtp(e, "email");
                        }
                      }}
                      className={Styles.ableResend}
                    >
                      {"Kirim ulang OTP ke Email"}
                    </button>
                  </div>
                  <div className={Styles.timerWrapper}>
                    <svg width="16" height="16" viewBox="0 0 36 36">
                      <circle
                        cx="18"
                        cy="18"
                        r="16"
                        fill="none"
                        stroke="#e6e6e6" // Warna abu-abu
                        strokeWidth="4" // Tebal garis abu-abu
                      />
                      <circle
                        cx="18"
                        cy="18"
                        r="16"
                        fill="none"
                        stroke="#007bff" // Warna biru
                        strokeWidth="4" // Tebal garis biru, ubah ke nilai lebih besar jika ingin lebih tebal
                        strokeDasharray="100 100"
                        strokeDashoffset={100 - circularValue}
                        style={{ transition: "stroke-dashoffset 0.3s ease" }}
                      />
                    </svg>
                    <span>{secondsToTime(otpTimer)}</span>
                  </div>
                </div>

                {alertVerify && (
                  <div className={Styles.error}>*{alertVerify}</div>
                )}

                <button
                  className={Styles.button}
                  type="submit"
                  disabled={isLoadingVerify || isLoadingLinkJobPost}
                >
                  {isLoadingVerify || isLoadingLinkJobPost ? (
                    <Spinner />
                  ) : (
                    " Lanjut Konfirmasi"
                  )}
                </button>
              </div>
            </form>
          ) : (
            <form
              onSubmit={isLoadingRegist ? null : handleSubmit}
              className={`${Styles.list} ${query?.get("h") && Styles.listOtp}`}
            >
              {login ? (
                <div className={Styles.input}>
                  <div>
                    <div className={Styles.icon}>
                      <Icon icon={"user-outline"} size={24} color={"#1C1C1C"} />
                    </div>
                    <input
                      autocomplete="off" // Nonaktifkan autofill
                      className={`${Styles.inputSearch} ${
                        (validate && !phoneNumber) ||
                        (validate &&
                          /[a-zA-Z]/.test(phoneNumber) &&
                          !validateEmail(phoneNumber))
                          ? Styles.error
                          : ""
                      }`}
                      value={phoneNumber}
                      onChange={(e) => {
                        const value = e.target.value;
                        // Regex to allow letters, numbers, '@', '.', and '_', which are common in emails
                        if (/^[a-zA-Z0-9@._]*$/.test(value)) {
                          setPhoneNumber(value.toLowerCase()); // Convert to lowercase
                        }
                      }}
                      placeholder="Email atau nomor Whatsapp"
                    />
                  </div>

                  <span className={Styles.desc}>
                    Gunakan “8” diawal, contoh : 8123456789
                  </span>
                </div>
              ) : (
                <div className={Styles.input}>
                  <div>
                    <div className={Styles.icon}>
                      <Icon icon={"user-outline"} size={24} color={"#1C1C1C"} />
                    </div>
                    <input
                      autocomplete="off" // Nonaktifkan autofill
                      className={`${Styles.inputSearch} ${
                        validate && !name && Styles.error
                      }`}
                      value={name}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (/^[a-zA-Z\s.,]*$/.test(value)) {
                          setName(value?.toUpperCase()); // Hanya perbarui jika input valid
                        }
                      }}
                      placeholder="Nama lengkap"
                    />
                  </div>

                  <div>
                    <div className={Styles.icon}>
                      <Icon icon={"whatsapp"} size={24} color={"#1C1C1C"} />
                    </div>
                    <input
                      autocomplete="off" // Nonaktifkan autofill
                      className={`${Styles.inputSearch} ${
                        validate && !phoneNumber && Styles.error
                      }`}
                      value={phoneNumber}
                      onChange={(e) => {
                        const value = e.target.value;
                        // Perbolehkan input kosong atau yang dimulai dengan '8'
                        if (value === "" || /^8\d*$/.test(value)) {
                          setPhoneNumber(value);
                        }
                      }}
                      placeholder="Nomor Whatsapp, ex: 8123456789"
                      //   inputMode="numeric"
                    />
                  </div>

                  <div>
                    <div className={Styles.icon}>
                      <Icon
                        icon={"email-outline"}
                        size={24}
                        color={"#1C1C1C"}
                      />
                    </div>
                    <input
                      autocomplete="off" // Nonaktifkan autofill
                      className={`${Styles.inputSearch} ${
                        (validate && !email) ||
                        (validate && !validateEmail(email))
                          ? Styles.error
                          : ""
                      }`}
                      value={email}
                      onChange={(e) => {
                        const value = e.target.value.toLowerCase();
                        // Konversi ke huruf kecil dan hilangkan spasi
                        if (/^[a-zA-Z0-9@._]*$/.test(value)) {
                          setEmail(value);
                        }
                      }}
                      placeholder="Email"
                      // autoComplete="new-email"
                    />
                  </div>
                </div>
              )}

              <div className={Styles.buttonWrapper}>
                {alert && <div className={Styles.error}>*{alert}</div>}

                <button
                  className={Styles.button}
                  type="submit"
                  disabled={isLoadingRegist}
                >
                  {isLoadingRegist ? (
                    <Spinner />
                  ) : login ? (
                    "Masuk"
                  ) : (
                    "Daftar Sekarang"
                  )}
                </button>
                {login ? (
                  <span>
                    Belum Punya Akun?{" "}
                    <span
                      onClick={() => {
                        navigate(`/user/register`);
                        setPhoneNumber("");
                        setName("");
                        setEmail("");
                        setValidate(false);
                      }}
                    >
                      Daftar Sekarang
                    </span>
                  </span>
                ) : (
                  <span>
                    Sudah Punya Akun?{" "}
                    <span
                      onClick={() => {
                        navigate(`/user/login`);
                        setPhoneNumber("");
                        setName("");
                        setEmail("");
                        setValidate(false);
                      }}
                    >
                      Masuk Sekarang
                    </span>
                  </span>
                )}
              </div>
            </form>
          )}
        </div>
        <img src={Images.ICON_VECTOR} alt="" className={Styles.vector} />
      </div>

      <div className={Styles.circleBottom}></div>
    </div>
  );
}
