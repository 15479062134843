/* eslint-disable no-unused-vars */

import {
  loader as flbLoader,
  pdfPreviewLoader,
  logHistoryLoader,
} from "@Organism/Manager/Doc";
import ErrorBoundary from "@Organism/Utils/ErrorBoundary";
import { loader as contratViewLoader } from "@Pages/Additional/BMContractView";
import { loader as loginRegister } from "@Pages/Home/RegisterUser/index";
import { loader as candidateContractLoader } from "@Pages/Additional/CandidateContract";
import { loader as candidateFormLoader } from "@Pages/Additional/CandidateForm";
import { loader as candidatesRecommendationLoader } from "@Pages/Additional/CandidatesRecommendation";
import { loader as invitationLoader } from "@Pages/Additional/InvitationSPA";
import { loader as jobApplicationLoader } from "@Pages/Additional/JobApplicationForm";
import { loader as clientAssignmentLoader } from "@Pages/Additional/NewClientAssignment";
import { loader as LoaderOfferTransition } from "@Pages/Additional/OfferTransition";
import { loader as candidateRenewLoader } from "@Pages/Additional/RenewData";
import { loader as candidateDetailsLoader } from "@Pages/Auth/BusinessSupport/Candidates/CandidateDetails";
import { loader as candidateListLoader } from "@Pages/Auth/BusinessSupport/Candidates/CandidateList";
import { loader as pendingContractLoader } from "@Pages/Auth/BusinessSupport/Contracts/PendingContractList";
import { loader as employeeDetailLoader } from "@Pages/Auth/Client/Employee/EmployeeDetail";
import { loader as employeeListLoader } from "@Pages/Auth/Client/Employee/EmployeeList";
import { loader as manPowerRequestSummaryLoaderClient } from "@Pages/Auth/Client/ManPowerRequestSummary";
import { loader as mpoLoader } from "@Pages/Auth/Client/Order/ManPoweOutsource";
import { loader as clientOrderDetailsLoader } from "@Pages/Auth/Client/Orders/OrderDetails";
import { loader as clientOrderListLoader } from "@Pages/Auth/Client/Orders/OrderList";
import { loader as dashboardLoader } from "@Pages/Auth/Dashboard";
import { loader as HumanCapitalContractListLoader } from "@Pages/Auth/HumanCapital/Contracts/ContractList";
import { loader as createContractPageLoader } from "@Pages/Auth/HumanCapital/CreateContract";
import { loader as editContractPageLoader } from "@Pages/Auth/HumanCapital/EditDraft";
import { loader as flbVerificationLoader } from "@Pages/Auth/HumanCapital/FLBVerification/FLBVerificationList";
import { loader as flbVerificationDetailsLoader } from "@Pages/Auth/HumanCapital/FLBVerification/FLBVerificationDetails";
import { loader as approvalListLoader } from "@Pages/Auth/Manager/Approval/ApprovalList";
import { loader as addOrderLoader } from "@Pages/Auth/Manager/Client/AddNewOrder";
import { loader as clientDetailLoader } from "@Pages/Auth/Manager/Client/ClientDetail";
import { loader as clientListLoader } from "@Pages/Auth/Manager/Client/ClientList";
import { loader as manPowerRequestSummaryLoader } from "@Pages/Auth/Manager/Client/ManPowerRequestSummary";
import { loader as schemeEditLoader } from "@Pages/Auth/Manager/Client/WorkSchemes/EditScheme";
import { loader as schemeDetailLoader } from "@Pages/Auth/Manager/Client/WorkSchemes/SchemeDetail";
import { loader as workSchemeLoader } from "@Pages/Auth/Manager/Client/WorkSchemes/SchemeList";
import { loader as pipelineListLoader } from "@Pages/Auth/Manager/Pipeline/PipelineList";
import { loader as pipelineOrderLoader } from "@Pages/Auth/Manager/Pipeline/PipelineOrder";
import { loader as pipelineOrderDetailsLoader } from "@Pages/Auth/Manager/Pipeline/PipelineOrderDetails";
import { loader as addJobPostingLoader } from "@Pages/Auth/Recruiter/JobPosting/AddJobPosting";
import { loader as jobPostingListLoader } from "@Pages/Auth/Recruiter/JobPosting/JobPostingList";
import PipelinePage, {
  loader as loaderPipeline,
} from "@Pages/Auth/Recruiter/Pipeline";
import PipelineDetailPage, {
  loader as loaderPipelineDetail,
} from "@Pages/Auth/Recruiter/PipelineDetail";
import { loader as recruitmentDetailLoader } from "@Pages/Auth/Recruiter/Recruitment/RecruitmentDetail";
import RecruitmentList, {
  loader as recruitmentListLoader,
} from "@Pages/Auth/Recruiter/Recruitment/RecruitmentList";
import { loader as tipsLoader } from "@Pages/Auth/Recruiter/Recruitment/TipsAndTricks";
import { loader as loaderJobSeekerList } from "@Pages/Home/JobSeakerDetails";
import { loader, loader as loaderJobSeekerHome } from "@Pages/Home/JobSeeker";
import { loader as LoginLoader } from "@Pages/Login";
import { addClientSyncOptions } from "@Services/manager/client";
import { loader as AppLoader, toolsLoader } from "App";
import { loader as recruiterCandidateListLoader } from "@Pages/Auth/Recruiter/RecruiterCandidateList";
import { loader as schemeProceedLoader } from "@Pages/Auth/Manager/Client/WorkSchemes/ProceedScheme";
import { loader as JobPostingDetailLoader } from "@Pages/Auth/Recruiter/JobPosting/JobPostingDetail";
import { loader as recruitmentListPageLoader } from "@Pages/Auth/Recruiter/Recruitment/RecruitmentListPage";
import { loader as extendContractLoader } from "@Pages/Auth/BusinessSupport/Contracts/ExtendContract";
import { loader as bmListLoader } from "@Pages/Auth/Manager/BM";
import { loader as pendingContractDetailLoader } from "@Pages/Auth/BusinessSupport/Contracts/PendingContractDetail";
import { loader as completeContractDetailLoader } from "@Pages/Auth/BusinessSupport/Contracts/CompleteContractList";
import { loader as FulfillmentDetailRBMLoader } from "@Pages/Auth/Manager/FulfillmentDetailRBM";
import { loader as loaderUpdateProfileLanding } from "@Pages/Home/UpdateProfileUser";
import { loader as loaderDetailProfileLanding } from "@Pages/Home/SettingProfileUser";
import { lazy, Suspense } from "react";
import LoadingAnimationPage from "@Organism/LoadingAnimationPage";
import { element } from "prop-types";
// import RegisterUserPage from "@Pages/Home/RegisterUser";

const Doc = lazy(() => import("@Organism/Manager/Doc"));
const App = lazy(() => import("App"));
const ClientList = lazy(() => import("@Pages/Auth/Manager/Client/ClientList"));
const ClientDetail = lazy(() =>
  import("@Pages/Auth/Manager/Client/ClientDetail")
);
const AddNewOrder = lazy(() =>
  import("@Pages/Auth/Manager/Client/AddNewOrder")
);
const ClientOrderDetails = lazy(() =>
  import("@Pages/Auth/Client/Orders/OrderDetails")
);
const SchemeDetail = lazy(() =>
  import("@Pages/Auth/Manager/Client/WorkSchemes/SchemeDetail")
);
const FulfillmentDetailRBMPage = lazy(() =>
  import("@Pages/Auth/Manager/FulfillmentDetailRBM")
);
const Dashboard = lazy(() => import("@Pages/Auth/Dashboard"));
const NewClientDashboard = lazy(() =>
  import("@Pages/Auth/Client/NewDashboard")
);
const AddNewClient = lazy(() =>
  import("@Pages/Auth/Manager/Client/AddNewClient")
);
const ManPowerRequestSummary = lazy(() =>
  import("@Pages/Auth/Manager/Client/ManPowerRequestSummary")
);
const UnderMaintenacePage = lazy(() =>
  import("@Organism/Utils/UnderMaintenance")
);
const ClientWorkSchemes = lazy(() =>
  import("@Pages/Auth/Manager/Client/WorkSchemes/SchemeList")
);
const SchemeEditForm = lazy(() =>
  import("@Pages/Auth/Manager/Client/WorkSchemes/EditScheme")
);
const SchemeProceedForm = lazy(() =>
  import("@Pages/Auth/Manager/Client/WorkSchemes/ProceedScheme")
);
const PipelineList = lazy(() =>
  import("@Pages/Auth/Manager/Pipeline/PipelineList")
);
const PipelineOrder = lazy(() =>
  import("@Pages/Auth/Manager/Pipeline/PipelineOrder")
);
const PipelineOrderDetails = lazy(() =>
  import("@Pages/Auth/Manager/Pipeline/PipelineOrderDetails")
);
const ApprovalList = lazy(() =>
  import("@Pages/Auth/Manager/Approval/ApprovalList")
);
const BMListPage = lazy(() => import("@Pages/Auth/Manager/BM"));
const ManPowerRequestSummaryClient = lazy(() =>
  import("@Pages/Auth/Client/ManPowerRequestSummary")
);

const RecruitmentListPage = lazy(() =>
  import("@Pages/Auth/Recruiter/Recruitment/RecruitmentListPage")
);
const RecruitmentAreaDetail = lazy(() =>
  import("@Pages/Auth/Recruiter/Recruitment/RecruitmentAreaDetail")
);
const RecruitmentDetail = lazy(() =>
  import("@Pages/Auth/Recruiter/Recruitment/RecruitmentDetail")
);
const JobPostingListPage = lazy(() =>
  import("@Pages/Auth/Recruiter/JobPosting/JobPostingList")
);
const AddJobPostingPage = lazy(() =>
  import("@Pages/Auth/Recruiter/JobPosting/AddJobPosting")
);
const JobPostingDetailPage = lazy(() =>
  import("@Pages/Auth/Recruiter/JobPosting/JobPostingDetail")
);
const ChatPage = lazy(() => import("@Pages/Auth/Recruiter/Chat"));
const CreateTipsAndTricksPage = lazy(() =>
  import("@Pages/Auth/Recruiter/Recruitment/CreateTipsAndTricks")
);
const TipsAndTrickDetailPage = lazy(() =>
  import("@Pages/Auth/Recruiter/Recruitment/TipsAndTrickDetail")
);
const RecruiterCandidateList = lazy(() =>
  import("@Pages/Auth/Recruiter/RecruiterCandidateList")
);

const CandidateList = lazy(() =>
  import("@Pages/Auth/BusinessSupport/Candidates/CandidateList")
);
const CandidateDetails = lazy(() =>
  import("@Pages/Auth/BusinessSupport/Candidates/CandidateDetails")
);
const ContractList = lazy(() =>
  import("@Pages/Auth/BusinessSupport/Contracts/ContractList")
);
const ExtendContract = lazy(() =>
  import("@Pages/Auth/BusinessSupport/Contracts/ExtendContract")
);
const PendingContractList = lazy(() =>
  import("@Pages/Auth/BusinessSupport/Contracts/PendingContractList")
);
const PendingContractDetail = lazy(() =>
  import("@Pages/Auth/BusinessSupport/Contracts/PendingContractDetail")
);
const CompleteContractList = lazy(() =>
  import("@Pages/Auth/BusinessSupport/Contracts/CompleteContractList")
);
const HumanCapitalContractList = lazy(() =>
  import("@Pages/Auth/HumanCapital/Contracts/ContractList")
);
const EditTemplatePage = lazy(() =>
  import("@Pages/Auth/HumanCapital/EditDraft")
);
const CreateContractPage = lazy(() =>
  import("@Pages/Auth/HumanCapital/CreateContract")
);
const FLBVerificationListPage = lazy(() =>
  import("@Pages/Auth/HumanCapital/FLBVerification/FLBVerificationList")
);
const FLBVerificationDetailsPage = lazy(() =>
  import("@Pages/Auth/HumanCapital/FLBVerification/FLBVerificationDetails")
);
const JobApplicationForm = lazy(() =>
  import("@Pages/Additional/JobApplicationForm")
);
const InvitationSPA = lazy(() => import("@Pages/Additional/InvitationSPA"));
const CandidateForm = lazy(() => import("@Pages/Additional/CandidateForm"));
const OfferTransition = lazy(() => import("@Pages/Additional/OfferTransition"));
const DocView = lazy(() => import("@Pages/Additional/DocView"));
const ClientAssignmentSPA = lazy(() =>
  import("@Pages/Additional/NewClientAssignment")
);
const CandidatesRecommendationSPA = lazy(() =>
  import("@Pages/Additional/CandidatesRecommendation")
);
const BmContractView = lazy(() => import("@Pages/Additional/BMContractView"));
const CandidateContract = lazy(() =>
  import("@Pages/Additional/CandidateContract")
);
const RenewData = lazy(() => import("@Pages/Additional/RenewData"));

const HogieSandbox = lazy(() => import("@Organism/Sandbox/Hogie"));
const RiskasSandbox = lazy(() => import("@Organism/Sandbox/Riska"));
const BagusSandbox = lazy(() => import("@Organism/Sandbox/Bagus"));
const Tasya = lazy(() => import("@Organism/Sandbox/Tasya"));
const NotFoundPage = lazy(() => import("@Organism/Utils/NotFound"));
const Login = lazy(() => import("@Pages/Login"));
const Logout = lazy(() => import("@Pages/Logout"));

const JobSeekersHomePage = lazy(() => import("@Pages/Home/JobSeeker"));
const BusinessHomePage = lazy(() => import("@Pages/Home/Business"));
const JobSeakerDetailPage = lazy(() => import("@Pages/Home/JobSeakerDetails"));
const ClientLogin = lazy(() => import("@Pages/Home/Login"));
const ClientRegister = lazy(() => import("@Pages/Home/Register"));
const ClientForgotPassword = lazy(() => import("@Pages/Home/ForgotPassword"));
const ClientOrderList = lazy(() =>
  import("@Pages/Auth/Client/Orders/OrderList")
);
const EmployeeList = lazy(() =>
  import("@Pages/Auth/Client/Employee/EmployeeList")
);
const EmployeeDetail = lazy(() =>
  import("@Pages/Auth/Client/Employee/EmployeeDetail")
);
const ManPowerOutsource = lazy(() =>
  import("@Pages/Auth/Client/Order/ManPoweOutsource")
);
const PrivacyPolicyPage = lazy(() => import("@Pages/Additional/PrivacyPolicy"));
const RegisterUserPage = lazy(() => import("@Pages/Home/RegisterUser"));
const UpdateProfileUserPage = lazy(() =>
  import("@Pages/Home/UpdateProfileUser")
);
const SettingProfileUserPage = lazy(() =>
  import("@Pages/Home/SettingProfileUser")
);
// const i = lazy(() => import(""))
// const i = lazy(() => import(""))
// const i = lazy(() => import(""))
// const i = lazy(() => import(""))
// const i = lazy(() => import(""))
// const i = lazy(() => import(""))

const {
  createBrowserRouter,
  redirect,
  Outlet,
  defer,
  Navigate,
} = require("react-router-dom");

const ToolsProtectedRoutes = () => {
  // TODO: Use authentication token
  const localStorageToken = localStorage.getItem("accessToken");

  return localStorageToken ? <Outlet /> : <Navigate to="/sign-in" replace />;
};

const tools = [
  {
    path: "/",
    element: (
      <Suspense fallback={<LoadingAnimationPage />}>
        <App />
      </Suspense>
    ),
    errorElement: <ErrorBoundary />,
    loader: toolsLoader,
    children: [
      {
        element: <ToolsProtectedRoutes />,
        children: [
          {
            index: true,
            loader: dashboardLoader,
            element: (
              <Suspense fallback={<LoadingAnimationPage />}>
                <Dashboard />
              </Suspense>
            ),
          },
          {
            loader: () => {
              const isBM =
                localStorage.getItem("role") === "BUSINESS MANAGER" ||
                localStorage.getItem("role") === "REGION BUSINESS MANAGER" ||
                localStorage.getItem("role") === "BUSINESS DIRECTOR"
                  ? true
                  : false;
              if (!isBM) {
                throw redirect("/");
              }
              return null;
            },
            element: <Outlet />,
            children: [
              {
                path: "fulfillment",
                element: (
                  <Suspense fallback={<LoadingAnimationPage />}>
                    <NewClientDashboard isBM={true} />
                  </Suspense>
                ),
              },
              {
                path: "clients",
                children: [
                  {
                    index: true,
                    loader: clientListLoader,
                    element: (
                      <Suspense fallback={<LoadingAnimationPage />}>
                        <ClientList />
                      </Suspense>
                    ),
                  },
                  {
                    path: ":id/fulfillment",
                    loader: FulfillmentDetailRBMLoader,
                    element: (
                      <Suspense fallback={<LoadingAnimationPage />}>
                        <FulfillmentDetailRBMPage />
                      </Suspense>
                    ),
                  },
                  {
                    path: "add-new-client",
                    loader: async () => {
                      return defer({
                        packageSync: addClientSyncOptions(),
                      });
                    },
                    element: (
                      <Suspense fallback={<LoadingAnimationPage />}>
                        <AddNewClient />
                      </Suspense>
                    ),
                  },
                  {
                    path: "details",
                    children: [
                      {
                        index: true,
                        loader: clientDetailLoader,
                        errorElement: <ErrorBoundary />,
                        element: (
                          <Suspense fallback={<LoadingAnimationPage />}>
                            <ClientDetail />
                          </Suspense>
                        ),
                      },
                      {
                        path: "man-power-request",
                        loader: manPowerRequestSummaryLoader,
                        element: (
                          <Suspense fallback={<LoadingAnimationPage />}>
                            <ManPowerRequestSummary />
                          </Suspense>
                        ),
                      },
                      {
                        path: "candidate",
                        element: (
                          <Suspense fallback={<LoadingAnimationPage />}>
                            <UnderMaintenacePage />
                          </Suspense>
                        ),
                      },
                    ],
                  },

                  {
                    path: "orders",
                    children: [
                      {
                        index: true,
                        loader: workSchemeLoader,
                        element: (
                          <Suspense fallback={<LoadingAnimationPage />}>
                            <ClientWorkSchemes />
                          </Suspense>
                        ),
                      },
                      {
                        path: "edit",
                        loader: schemeEditLoader,
                        element: (
                          <Suspense fallback={<LoadingAnimationPage />}>
                            <SchemeEditForm />
                          </Suspense>
                        ),
                      },
                      {
                        path: "proceed",
                        loader: schemeProceedLoader,
                        element: (
                          <Suspense fallback={<LoadingAnimationPage />}>
                            <SchemeProceedForm />
                          </Suspense>
                        ),
                      },
                      {
                        path: "details/:id",
                        loader: schemeDetailLoader,
                        element: (
                          <Suspense fallback={<LoadingAnimationPage />}>
                            <SchemeDetail />
                          </Suspense>
                        ),
                      },
                    ],
                  },
                  {
                    path: "add-new-order",
                    loader: addOrderLoader,
                    errorElement: <ErrorBoundary />,
                    element: (
                      <Suspense fallback={<LoadingAnimationPage />}>
                        <AddNewOrder />
                      </Suspense>
                    ),
                  },
                ],
              },
              {
                path: "pipeline",
                children: [
                  {
                    index: true,
                    loader: pipelineListLoader,
                    element: (
                      <Suspense fallback={<LoadingAnimationPage />}>
                        <PipelineList />
                      </Suspense>
                    ),
                  },
                  {
                    path: "order",
                    children: [
                      {
                        index: true,
                        loader: pipelineOrderLoader,
                        element: (
                          <Suspense fallback={<LoadingAnimationPage />}>
                            <PipelineOrder />
                          </Suspense>
                        ),
                      },
                      {
                        path: "details",
                        loader: pipelineOrderDetailsLoader,
                        element: (
                          <Suspense fallback={<LoadingAnimationPage />}>
                            <PipelineOrderDetails />
                          </Suspense>
                        ),
                      },
                    ],
                  },
                ],
              },
              {
                path: "approval",
                children: [
                  {
                    index: true,
                    loader: approvalListLoader,
                    element: (
                      <Suspense fallback={<LoadingAnimationPage />}>
                        <ApprovalList />
                      </Suspense>
                    ),
                  },
                ],
              },
              {
                path: "daftar-bm",
                children: [
                  {
                    index: true,
                    loader: bmListLoader,
                    element: (
                      <Suspense fallback={<LoadingAnimationPage />}>
                        <BMListPage />
                      </Suspense>
                    ),
                  },
                  {
                    path: ":id",
                    children: [
                      {
                        index: true,
                        loader: clientListLoader,
                        element: (
                          <Suspense fallback={<LoadingAnimationPage />}>
                            <ClientList rbmView />
                          </Suspense>
                        ),
                      },
                      {
                        path: "details",
                        children: [
                          {
                            index: true,
                            loader: clientDetailLoader,
                            errorElement: <ErrorBoundary />,
                            element: (
                              <Suspense fallback={<LoadingAnimationPage />}>
                                <ClientDetail rbmView />
                              </Suspense>
                            ),
                          },
                          {
                            path: "man-power-request",
                            loader: manPowerRequestSummaryLoader,
                            element: (
                              <Suspense fallback={<LoadingAnimationPage />}>
                                <ManPowerRequestSummary />
                              </Suspense>
                            ),
                          },
                          {
                            path: "candidate",
                            element: (
                              <Suspense fallback={<LoadingAnimationPage />}>
                                <UnderMaintenacePage />
                              </Suspense>
                            ),
                          },
                        ],
                      },
                      {
                        path: "fulfillment",
                        loader: FulfillmentDetailRBMLoader,
                        element: (
                          <Suspense fallback={<LoadingAnimationPage />}>
                            <FulfillmentDetailRBMPage />
                          </Suspense>
                        ),
                      },
                    ],
                  },
                ],
              },
              {
                path: "tambah-lokasi",
                children: [
                  {
                    index: true,
                    element: (
                      <Suspense fallback={<LoadingAnimationPage />}>
                        <UnderMaintenacePage />
                      </Suspense>
                    ),
                  },
                ],
              },
              {
                path: "recruitment",
                children: [
                  {
                    index: true,
                    element: (
                      <Suspense fallback={<LoadingAnimationPage />}>
                        <UnderMaintenacePage />
                      </Suspense>
                    ),
                  },
                ],
              },
            ],
          },
          {
            loader: () => {
              const isRO =
                localStorage.getItem("role") === "RECRUITMENT OFFICER"
                  ? true
                  : false;
              if (!isRO) {
                throw redirect("/");
              }
              return null;
            },
            element: <Outlet />,
            children: [
              {
                path: "rekrutmen",
                children: [
                  {
                    index: true,
                    loader: recruitmentListPageLoader,
                    // errorElement: <ErrorBoundary />,
                    element: (
                      <Suspense fallback={<LoadingAnimationPage />}>
                        <RecruitmentListPage />
                      </Suspense>
                    ),
                  },
                  {
                    path: "area",
                    errorElement: <ErrorBoundary />,
                    element: (
                      <Suspense fallback={<LoadingAnimationPage />}>
                        <RecruitmentAreaDetail />
                      </Suspense>
                    ),
                  },
                  {
                    path: "details",
                    loader: recruitmentDetailLoader,
                    errorElement: <ErrorBoundary />,
                    element: (
                      <Suspense fallback={<LoadingAnimationPage />}>
                        <RecruitmentDetail />
                      </Suspense>
                    ),
                  },
                ],
              },
              {
                path: "jadwal",
                element: (
                  <Suspense fallback={<LoadingAnimationPage />}>
                    <UnderMaintenacePage />
                  </Suspense>
                ),
              },
              {
                path: "job-posting",
                children: [
                  {
                    index: true,
                    loader: jobPostingListLoader,
                    element: (
                      <Suspense fallback={<LoadingAnimationPage />}>
                        <JobPostingListPage />
                      </Suspense>
                    ),
                  },
                  {
                    path: "new/:id",
                    loader: addJobPostingLoader,
                    element: (
                      <Suspense fallback={<LoadingAnimationPage />}>
                        <AddJobPostingPage />
                      </Suspense>
                    ),
                  },
                  {
                    path: ":id",
                    loader: JobPostingDetailLoader,
                    element: (
                      <Suspense fallback={<LoadingAnimationPage />}>
                        <JobPostingDetailPage />
                      </Suspense>
                    ),
                  },
                ],
              },
              // {
              //   path: "pipeline-list",
              //   children: [
              //     {
              //       index: true,
              //       loader: loaderPipeline,
              //       element: <PipelinePage />,
              //     },
              //     {
              //       path: ":id",
              //       loader: loaderPipelineDetail,
              //       element: <PipelineDetailPage />,
              //     },
              //   ],
              // },
              {
                path: "klien",
                element: (
                  <Suspense fallback={<LoadingAnimationPage />}>
                    <UnderMaintenacePage />
                  </Suspense>
                ),
              },
              {
                path: "chat",
                element: (
                  <Suspense fallback={<LoadingAnimationPage />}>
                    <ChatPage />
                  </Suspense>
                ),
              },
              {
                path: "tips-&-trick",
                element: <Outlet />,
                children: [
                  {
                    path: "",
                    element: (
                      <Suspense fallback={<LoadingAnimationPage />}>
                        <UnderMaintenacePage />
                      </Suspense>
                    ),
                    loader: tipsLoader,
                  },
                  {
                    path: "new-post",
                    element: (
                      <Suspense fallback={<LoadingAnimationPage />}>
                        <CreateTipsAndTricksPage />
                      </Suspense>
                    ),
                  },
                  {
                    path: "article/:id",
                    element: (
                      <Suspense fallback={<LoadingAnimationPage />}>
                        <TipsAndTrickDetailPage type="article" />
                      </Suspense>
                    ),
                  },
                  {
                    path: "video/:id",
                    element: (
                      <Suspense fallback={<LoadingAnimationPage />}>
                        <TipsAndTrickDetailPage type="video" />
                      </Suspense>
                    ),
                  },
                ],
              },
              {
                path: "tugas",
                element: (
                  <Suspense fallback={<LoadingAnimationPage />}>
                    <UnderMaintenacePage />
                  </Suspense>
                ),
              },
              {
                path: "laporan",
                element: (
                  <Suspense fallback={<LoadingAnimationPage />}>
                    <UnderMaintenacePage />
                  </Suspense>
                ),
              },
              {
                path: "daftar-kandidat-public",
                children: [
                  {
                    index: true,
                    loader: recruiterCandidateListLoader,
                    element: (
                      <Suspense fallback={<LoadingAnimationPage />}>
                        <RecruiterCandidateList mode="public" />
                      </Suspense>
                    ),
                    errorElement: <ErrorBoundary />,
                  },
                ],
              },
              {
                path: "daftar-kandidat-private",
                children: [
                  {
                    index: true,
                    loader: recruiterCandidateListLoader,
                    element: (
                      <Suspense fallback={<LoadingAnimationPage />}>
                        <RecruiterCandidateList mode="private" />
                      </Suspense>
                    ),
                    errorElement: <ErrorBoundary />,
                  },
                ],
              },
              {
                path: "daftar-kandidat-unprocessed",
                children: [
                  {
                    index: true,
                    loader: recruiterCandidateListLoader,
                    element: (
                      <Suspense fallback={<LoadingAnimationPage />}>
                        <RecruiterCandidateList mode="unprocessed" />
                      </Suspense>
                    ),
                    errorElement: <ErrorBoundary />,
                  },
                ],
              },
            ],
          },
          {
            loader: () => {
              const isBS =
                localStorage.getItem("role") === "BUSINESS SUPPORT"
                  ? true
                  : false;
              if (!isBS) {
                throw redirect("/");
              }
              return null;
            },
            element: <Outlet />,
            children: [
              {
                path: "candidates",
                children: [
                  {
                    index: true,
                    element: (
                      <Suspense fallback={<LoadingAnimationPage />}>
                        <CandidateList />
                      </Suspense>
                    ),
                    loader: candidateListLoader,
                    // element: <>test</>
                  },
                  {
                    path: ":id",
                    element: (
                      <Suspense fallback={<LoadingAnimationPage />}>
                        <CandidateDetails />
                      </Suspense>
                    ),
                    loader: candidateDetailsLoader,
                  },
                ],
              },
              {
                path: "data",
                children: [
                  {
                    path: "contracts",
                    element: (
                      <Suspense fallback={<LoadingAnimationPage />}>
                        <ContractList />
                      </Suspense>
                    ),
                  },
                  {
                    path: "contracts/:employeeId",
                    element: (
                      <Suspense fallback={<LoadingAnimationPage />}>
                        <ExtendContract />
                      </Suspense>
                    ),
                    loader: extendContractLoader,
                  },
                  {
                    path: "pending-contracts",
                    element: (
                      <Suspense fallback={<LoadingAnimationPage />}>
                        <PendingContractList />
                      </Suspense>
                    ),
                    loader: pendingContractLoader,
                  },
                  {
                    path: "pending-contracts/:employeeId",
                    element: (
                      <Suspense fallback={<LoadingAnimationPage />}>
                        <PendingContractDetail />
                      </Suspense>
                    ),
                    loader: pendingContractDetailLoader,
                  },
                  {
                    path: "complete-contracts",
                    element: (
                      <Suspense fallback={<LoadingAnimationPage />}>
                        <CompleteContractList />
                      </Suspense>
                    ),
                    loader: completeContractDetailLoader,
                  },
                ],
              },
            ],
          },
          {
            loader: () => {
              const isHC =
                localStorage.getItem("role") === "HUMAN CAPITAL MANAGER"
                  ? true
                  : false;
              if (!isHC) {
                throw redirect("/");
              }
              return null;
            },
            element: <Outlet />,
            children: [
              {
                path: "drafts-contract",
                children: [
                  {
                    index: true,
                    element: (
                      <Suspense fallback={<LoadingAnimationPage />}>
                        <HumanCapitalContractList isUnderMaintenance />
                      </Suspense>
                    ),
                    loader: HumanCapitalContractListLoader,
                    // element: <>test</>
                  },
                  {
                    path: ":id",
                    element: (
                      <Suspense fallback={<LoadingAnimationPage />}>
                        <CandidateDetails />
                      </Suspense>
                    ),
                    loader: candidateDetailsLoader,
                  },
                  {
                    path: "update-draft",
                    element: (
                      <Suspense fallback={<LoadingAnimationPage />}>
                        <EditTemplatePage />
                      </Suspense>
                    ),
                    loader: editContractPageLoader,
                  },
                ],
              },
              {
                path: "user",
                element: (
                  <Suspense fallback={<LoadingAnimationPage />}>
                    <UnderMaintenacePage />
                  </Suspense>
                ),
              },
              {
                path: "new-draft",
                loader: createContractPageLoader,
                element: (
                  <Suspense fallback={<LoadingAnimationPage />}>
                    <CreateContractPage />
                  </Suspense>
                ),
              },
              {
                path: "flb-verification",
                children: [
                  {
                    index: true,
                    loader: flbVerificationLoader,
                    element: (
                      <Suspense fallback={<LoadingAnimationPage />}>
                        <FLBVerificationListPage />
                      </Suspense>
                    ),
                  },
                  {
                    path: ":id",
                    loader: flbVerificationDetailsLoader,
                    element: (
                      <Suspense fallback={<LoadingAnimationPage />}>
                        <FLBVerificationDetailsPage />
                      </Suspense>
                    ),
                  },
                ],
              },
            ],
          },
          {
            path: "settings",
            element: (
              <Suspense fallback={<LoadingAnimationPage />}>
                <UnderMaintenacePage />
              </Suspense>
            ),
          },
          {
            path: "profile",
            element: (
              <Suspense fallback={<LoadingAnimationPage />}>
                <UnderMaintenacePage />
              </Suspense>
            ),
          },
        ],
      },
    ],
  },
  {
    path: "/job-application",
    children: [
      {
        index: true,
        loader: jobApplicationLoader,
        element: (
          <Suspense fallback={<LoadingAnimationPage />}>
            <JobApplicationForm />
          </Suspense>
        ),
      },
      {
        path: ":hash",
        loader: jobApplicationLoader,
        element: (
          <Suspense fallback={<LoadingAnimationPage />}>
            <JobApplicationForm />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: "/candidate-invitation/:hash",
    loader: invitationLoader,
    element: (
      <Suspense fallback={<LoadingAnimationPage />}>
        <InvitationSPA />
      </Suspense>
    ),
  },
  {
    path: "/candidate-offer/:hash",
    loader: candidateFormLoader,
    element: (
      <Suspense fallback={<LoadingAnimationPage />}>
        <CandidateForm />
      </Suspense>
    ),
  },
  {
    path: "/offer-transition/:hash",
    element: (
      <Suspense fallback={<LoadingAnimationPage />}>
        <OfferTransition />
      </Suspense>
    ),
    loader: LoaderOfferTransition,
  },
  {
    path: "/workorder/:hash",
    element: (
      <Suspense fallback={<LoadingAnimationPage />}>
        <Doc />
      </Suspense>
    ),
    errorElement: <ErrorBoundary />,
    loader: flbLoader,
  },
  {
    path: "view",
    element: (
      <Suspense fallback={<LoadingAnimationPage />}>
        <DocView />
      </Suspense>
    ),
  },
  {
    path: "/assign/:hash",
    element: (
      <Suspense fallback={<LoadingAnimationPage />}>
        <ClientAssignmentSPA />
      </Suspense>
    ),
    errorElement: <ErrorBoundary />,
    loader: clientAssignmentLoader,
  },
  {
    path: "/candidate-recomendation/:hash",
    loader: candidatesRecommendationLoader,
    element: (
      <Suspense fallback={<LoadingAnimationPage />}>
        <CandidatesRecommendationSPA />
      </Suspense>
    ),
  },
  {
    path: "/clients/orders/pdf-preview/:id",
    loader: pdfPreviewLoader,
    element: (
      <Suspense fallback={<LoadingAnimationPage />}>
        <Doc />
      </Suspense>
    ),
  },
  {
    path: "/flb-history/:id",
    loader: logHistoryLoader,
    element: (
      <Suspense fallback={<LoadingAnimationPage />}>
        <Doc />
      </Suspense>
    ),
  },
  {
    path: "/contract-approval/:hash",
    loader: contratViewLoader,
    element: (
      <Suspense fallback={<LoadingAnimationPage />}>
        <BmContractView />
      </Suspense>
    ),
  },
  {
    path: "/candidate-contract/:hash",
    loader: candidateContractLoader,
    errorElement: <ErrorBoundary />,
    element: (
      <Suspense fallback={<LoadingAnimationPage />}>
        <CandidateContract />
      </Suspense>
    ),
  },
  {
    path: "/update-data/:hash",
    errorElement: <ErrorBoundary />,
    loader: candidateRenewLoader,
    element: (
      <Suspense fallback={<LoadingAnimationPage />}>
        <RenewData />
      </Suspense>
    ),
  },
  {
    path: "/sign-example",
    element: (
      <Suspense fallback={<LoadingAnimationPage />}>
        <HogieSandbox />
      </Suspense>
    ),
  },
  {
    path: "*",
    element: (
      <Suspense fallback={<LoadingAnimationPage />}>
        <NotFoundPage />
      </Suspense>
    ),
  },

  {
    path: "/logout",
    element: (
      <Suspense fallback={<LoadingAnimationPage />}>
        <Logout />
      </Suspense>
    ),
  },
  {
    path: "/sign-in",
    element: (
      <Suspense fallback={<LoadingAnimationPage />}>
        <Login />
      </Suspense>
    ),
    loader: LoginLoader,
  },
];

const client = [
  {
    path: "/",
    element: (
      <Suspense fallback={<LoadingAnimationPage />}>
        <App />
      </Suspense>
    ),
    loader: AppLoader,
    children: [
      {
        index: true,
        element: (
          <Suspense fallback={<LoadingAnimationPage />}>
            <JobSeekersHomePage />
          </Suspense>
        ),
        loader: loaderJobSeekerHome,
      },
      {
        path: "business",
        element: (
          <Suspense fallback={<LoadingAnimationPage />}>
            <BusinessHomePage />
          </Suspense>
        ),
      },
      {
        path: "job-seeker",
        loader: loaderJobSeekerList,
        element: (
          <Suspense fallback={<LoadingAnimationPage />}>
            <JobSeakerDetailPage />
          </Suspense>
        ),
      },
      {
        path: "user",
        children: [
          {
            path: "register",
            loader: loginRegister,
            element: (
              <Suspense fallback={<LoadingAnimationPage />}>
                <RegisterUserPage />
              </Suspense>
            ),
          },
          {
            path: "login",
            loader: loginRegister,
            element: (
              <Suspense fallback={<LoadingAnimationPage />}>
                <RegisterUserPage login />
              </Suspense>
            ),
          },
          {
            loader: loaderUpdateProfileLanding,
            path: "update-profile",
            element: <UpdateProfileUserPage />,
          },
          {
            path: "setting",
            element: <SettingProfileUserPage />,
            loader: loaderDetailProfileLanding,
          },
        ],
      },
      {
        path: "login",
        element: (
          <Suspense fallback={<LoadingAnimationPage />}>
            <ClientLogin />
          </Suspense>
        ),
      },
      {
        path: "register",
        element: (
          <Suspense fallback={<LoadingAnimationPage />}>
            <ClientRegister />
          </Suspense>
        ),
      },
      {
        path: "forgot-password",
        element: (
          <Suspense fallback={<LoadingAnimationPage />}>
            <ClientForgotPassword />
          </Suspense>
        ),
      },
      {
        path: "dashboard",
        children: [
          {
            index: true,
            loader: dashboardLoader,
            element: (
              <Suspense fallback={<LoadingAnimationPage />}>
                <Dashboard />
              </Suspense>
            ),
          },
          {
            path: "man-power-request",
            loader: manPowerRequestSummaryLoaderClient,
            element: (
              <Suspense fallback={<LoadingAnimationPage />}>
                <ManPowerRequestSummaryClient />
              </Suspense>
            ),
          },
          {
            path: "orders",
            children: [
              {
                index: true,
                loader: clientOrderListLoader,
                element: (
                  <Suspense fallback={<LoadingAnimationPage />}>
                    <ClientOrderList />
                  </Suspense>
                ),
              },
              {
                path: "details/:id",
                loader: clientOrderDetailsLoader,
                element: (
                  <Suspense fallback={<LoadingAnimationPage />}>
                    <ClientOrderDetails />
                  </Suspense>
                ),
              },
            ],
          },
        ],
      },

      {
        path: "employee",
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={<LoadingAnimationPage />}>
                <EmployeeList />
              </Suspense>
            ),
            loader: employeeListLoader,
          },
          {
            path: "details",
            errorElement: <ErrorBoundary />,
            loader: employeeDetailLoader,
            element: (
              <Suspense fallback={<LoadingAnimationPage />}>
                <EmployeeDetail />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "order",
        children: [
          {
            path: "manpower-outsource",
            element: (
              <Suspense fallback={<LoadingAnimationPage />}>
                <ManPowerOutsource />
              </Suspense>
            ),
            loader: mpoLoader,
          },
        ],
      },
      {
        path: "settings",
        element: (
          <Suspense fallback={<LoadingAnimationPage />}>
            <UnderMaintenacePage />
          </Suspense>
        ),
      },
      {
        path: "profile",
        element: (
          <Suspense fallback={<LoadingAnimationPage />}>
            <UnderMaintenacePage />
          </Suspense>
        ),
      },
    ],
  },

  {
    path: "view",
    element: (
      <Suspense fallback={<LoadingAnimationPage />}>
        <DocView />
      </Suspense>
    ),
  },
  {
    path: "privacy-policy",
    element: (
      <Suspense fallback={<LoadingAnimationPage />}>
        <PrivacyPolicyPage />
      </Suspense>
    ),
  },
  {
    path: "*",
    element: (
      <Suspense fallback={<LoadingAnimationPage />}>
        <NotFoundPage />
      </Suspense>
    ),
  },
  {
    path: "/logout",
    element: (
      <Suspense fallback={<LoadingAnimationPage />}>
        <Logout />
      </Suspense>
    ),
  },
  // {
  //   path: "/job-application",
  //   // loader: LoginLoader,
  //   element: <JobApplicationForm />,
  // },

  // {
  //   path: '/candidate-recommendation/:hash',
  //   loader: candidatesRecommendationLoader,
  //   element: <CandidatesRecommendationSPA />
  // },
];

const sandbox = [
  {
    path: "/",
    children: [
      {
        index: true,
        element: (
          <div
            style={{
              width: "100vw",
              height: "100vh",
              display: "grid",
              placeItems: "center",
              color: "#9e9e9e",
            }}
          >
            #componentHunt
          </div>
        ),
      },
      {
        path: "riska",
        element: (
          <Suspense fallback={<LoadingAnimationPage />}>
            <RiskasSandbox />
          </Suspense>
        ),
      },
      {
        path: "hogie",
        element: (
          <Suspense fallback={<LoadingAnimationPage />}>
            <HogieSandbox />
          </Suspense>
        ),
      },
      {
        path: "tasya",
        element: (
          <Suspense fallback={<LoadingAnimationPage />}>
            <Tasya />
          </Suspense>
        ),
      },
      {
        path: "bagus",
        element: (
          <Suspense fallback={<LoadingAnimationPage />}>
            <BagusSandbox />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: "*",
    element: (
      <Suspense fallback={<LoadingAnimationPage />}>
        <NotFoundPage />
      </Suspense>
    ),
  },
];

const renewData = [
  {
    path: "/update-data/:hash",
    errorElement: <ErrorBoundary />,
    loader: candidateRenewLoader,
    element: (
      <Suspense fallback={<LoadingAnimationPage />}>
        <RenewData />
      </Suspense>
    ),
  },
];

export const router = createBrowserRouter(
  client
  // tools
  // sandbox
);
