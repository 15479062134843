import UpdateProfileUser from "@Organism/Home/UpdateProfileUser";
import LoadingAnimationPage from "@Organism/LoadingAnimationPage";
import AsyncErrorBoundary from "@Organism/Utils/AsyncErrorBoundary";
import { detailProfile, profileSync } from "@Services/home";
import { Suspense } from "react";
import { Await, defer, redirect, useLoaderData } from "react-router-dom";

export const loader = () => {
  const isComplete = localStorage.getItem("dataProfile");
  const accessToken = localStorage.getItem("accessTokenUserLanding");

  if (isComplete === "complete") {
    throw redirect("/");
  } else if (!isComplete || !accessToken) {
    throw redirect("/");
  }

  return defer({
    packageDetailProfileSync: detailProfile(),
    packageProfileSync: profileSync(),
  });
};
export default function UpdateProfileUserPage() {
  const { packageProfileSync, packageDetailProfileSync } = useLoaderData();

  // return <UpdateProfileUser />;
  return (
    <Suspense fallback={<LoadingAnimationPage />}>
      <Await
        resolve={packageDetailProfileSync}
        errorElement={
          <div style={{ height: "calc(100vh - 220px)" }}>
            <AsyncErrorBoundary />
          </div>
        }
      >
        {(packageDetailProfileSync) => (
          <Suspense fallback={<LoadingAnimationPage />}>
            <Await
              resolve={packageProfileSync}
              errorElement={
                <div style={{ height: "calc(100vh - 220px)" }}>
                  <AsyncErrorBoundary />
                </div>
              }
            >
              {(packageProfileSync) => (
                <UpdateProfileUser
                  initialData={packageDetailProfileSync?.response}
                  data={packageProfileSync?.response}
                />
              )}
            </Await>
          </Suspense>
        )}
      </Await>
    </Suspense>
  );
}
