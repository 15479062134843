import { sendLinkJobPost } from "@Services/officer/jobPosting";

export const handleSubmit = async (
  e,
  location,
  id,
  setIsLodingCopyLink = () => {},
  setError = () => {},
  width,
  login = false
) => {
  e.preventDefault();

  try {
    setIsLodingCopyLink(id);
    const response = await sendLinkJobPost({
      jobPostingID: id,
      city: location || "",
      type: "Normal",
      page: "Landing",
      recruiterID: "",
    });

    if (response?.code === 200) {
      if (width > 768) {
        window.open(response?.response);
      } else {
        window.location.href = response?.response;
      }

      setIsLodingCopyLink(null);
      setError(null);
    }
  } catch (error) {
    setIsLodingCopyLink(null);
    setError({
      error: error?.response?.data?.error,
      id: id,
    });
    if (login) {
      sessionStorage?.setItem(
        "errorLinkJobPost",
        error?.response?.data?.error || ""
      );
    }
  }
};
