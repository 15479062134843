import SettingProfileUser from "@Organism/Home/SettingProfileUser";
import LoadingAnimationPage from "@Organism/LoadingAnimationPage";
import AsyncErrorBoundary from "@Organism/Utils/AsyncErrorBoundary";
import { detailProfile, profileSync } from "@Services/home";
import { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";

export const loader = () => {
  return defer({
    packageDetailProfileSync: detailProfile(),
    packageProfileSync: profileSync(),
  });
};

export default function SettingProfileUserPage() {
  const { packageDetailProfileSync, packageProfileSync } = useLoaderData();
  // return <SettingProfileUser />;
  return (
    <Suspense fallback={<LoadingAnimationPage />}>
      <Await
        resolve={packageDetailProfileSync}
        errorElement={
          <div style={{ height: "calc(100vh - 220px)" }}>
            <AsyncErrorBoundary />
          </div>
        }
      >
        {(packageDetailProfileSync) => (
          <Suspense fallback={<LoadingAnimationPage />}>
            <Await
              resolve={packageProfileSync}
              errorElement={
                <div style={{ height: "calc(100vh - 220px)" }}>
                  <AsyncErrorBoundary />
                </div>
              }
            >
              {(packageProfileSync) => (
                <SettingProfileUser
                  sync={packageProfileSync?.response}
                  data={packageDetailProfileSync?.response}
                />
              )}
            </Await>
          </Suspense>
        )}
      </Await>
    </Suspense>
  );
}
