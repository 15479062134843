import RegisterUser from "@Organism/Home/RegisterUser";
import { redirect } from "react-router-dom";

export const loader = () => {
  // const accessToken = sessionStorage.getItem("accessToken");
  const accessToken = localStorage.getItem("accessTokenUserLanding");
  if (accessToken) {
    throw redirect("/");
  }

  return null;
};

export default function RegisterUserPage({ login = false }) {
  return <RegisterUser login={login} />;
}
