import { API } from "@Config/api";

export function getLandingSync() {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/landing-sync`);
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function userRegister(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(`/candidate-account/register`, payload);
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function userLogin(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(`/candidate-account/login`, payload);
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
export function userGoogleLogin(token) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(
        `/candidate-account/google`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function userVerify(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(`/candidate-account/verify`, payload);
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function profileSync() {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/candidate-account/profile-sync`);
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function setupProfile(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(`/candidate-account/profile`, payload, {
        headers: {
          Authorization: localStorage?.getItem("accessTokenUserLanding"),
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
export function updateProfile(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.patch(`/candidate-account/profile`, payload, {
        headers: {
          Authorization: localStorage?.getItem("accessTokenUserLanding"),
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
export function reVerificationEmailOrPhoneProfile(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(
        `/candidate-account/reverification`,
        payload,
        {
          headers: {
            Authorization: localStorage?.getItem("accessTokenUserLanding"),
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function detailProfile() {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/candidate-account/profile`, {
        headers: {
          Authorization: localStorage?.getItem("accessTokenUserLanding"),
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
