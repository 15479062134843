import Icon from "@Atom/Icon";
import Styles from "./styles.module.scss";
import { useRef, useState } from "react";
import Dropdown from "@Atom/DropDown";
import { sendLinkJobPost } from "@Services/officer/jobPosting";
import Spinner from "@Atom/Spinner";
import AutocompleteV2 from "@Atom/AutocompleteV2";
import useWindowSize from "@Hooks/useWindowSize";
import { useNavigate } from "react-router-dom";
// import Clipboard from "clipboard";

export default function JobPostingLinkModal({
  data,
  handleClose,
  setShowNotif = () => {},
  isRecrutiment,
}) {
  // console.log(data?.data);
  const [valueLocation, setValueLocation] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [validate, setValidate] = useState(false);
  const [error, setError] = useState("");
  const [isLoadingCopyLink, setIsLodingCopyLink] = useState(false);
  const copyBtnRef = useRef(null);
  const { width } = useWindowSize();

  const navigate = useNavigate();

  const handleCopy = (link) => {
    if (data?.isShow) {
      if (width > 768) {
        window.open(link);
      } else {
        window.location.href = link;
      }

      setIsLodingCopyLink(false);
      handleClose();
    } else {
      navigator.clipboard
        .writeText(link)
        .then(() => {
          setShowNotif(true);
          setIsLodingCopyLink(false);
          handleClose();
          setTimeout(() => {
            setShowNotif(false);
          }, 2000);
        })
        .catch((error) => {
          // Tangani error jika copy ke clipboard gagal
          console.error("Gagal menyalin ke clipboard:", error);
          // window.open(link, "_blank", "noopener,noreferrer");
          if (width > 768) {
            window.open(link);
          } else {
            window.location.href = link;
          }
          setIsLodingCopyLink(false);
          handleClose();
        });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!valueLocation) {
        setValidate(true);
        setError(`*Kota lokasi kerja belum terisi`);
      } else if (
        !isRecrutiment &&
        !localStorage?.getItem("accessTokenUserLanding")
      ) {
        navigate("/user/login", {
          state: {
            data: {
              id: data?.data?._id,
              location: valueLocation,
            },
          },
        });
      } else {
        setIsLodingCopyLink(true);

        const response = await sendLinkJobPost({
          jobPostingID: data?.data?._id,
          city: valueLocation || "",
          type: data?.name || "",
          page: isRecrutiment ? "Recruitment" : "Landing",
          recruiterID: isRecrutiment ? localStorage?.getItem("id") : "",
        });

        if (response?.code === 200) {
          handleCopy(response?.response);
        }
      }
    } catch (error) {
      console.log(error);
      setIsLodingCopyLink(false);
      setError(error?.response?.data?.error);
    }
  };

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        {!isRecrutiment ? (
          <span>Pilih Lokasi Kerja</span>
        ) : (
          <span>
            Salin Link Job Posting{" "}
            {data?.name === "Peralihan" ? "Peralihan" : ""}
          </span>
        )}

        <Icon
          icon={"cross"}
          size={20}
          className={Styles.cross}
          onClick={handleClose}
        />
      </div>

      <div className={Styles.content}>
        <div className={Styles.warning}>
          <div>
            <Icon icon={"bag-job"} size={16} color={"#1571DE"} />
          </div>
          <span>{data?.data?.title?.toUpperCase() || "-"}</span>
        </div>

        <div className={Styles.inputBox}>
          <span>
            Pilih Kota Lokasi Kerja<span>*</span>
          </span>
          <div>
            <AutocompleteV2
              placeholder={"Pilih Kota"}
              value={valueLocation}
              setValue={setValueLocation}
              isError={validate === true && !valueLocation}
              options={
                Array.isArray(data?.data?.placement)
                  ? data?.data?.placement?.length > 0
                    ? data?.data?.placement?.map((el) => {
                        return {
                          name: el?.city,
                          code: el?.code,
                        };
                      })
                    : []
                  : data?.data?.placement
                  ? data?.data?.placement
                      ?.split("::")
                      ?.map((word) => ({ name: word }))
                  : []
              }
            />
          </div>
        </div>

        <div className={Styles.buttonSection}>
          {error && <span>{error}</span>}
          <div className={Styles.button}>
            <div onClick={handleClose}>Batal</div>
            <div
              ref={copyBtnRef}
              className={`${isLoadingCopyLink && Styles.loading}`}
              onClick={isLoadingCopyLink ? null : handleSubmit}
            >
              {isLoadingCopyLink ? (
                <Spinner />
              ) : !isRecrutiment ? (
                "Lanjut"
              ) : (
                "Salin Link"
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
