import Icon from "@Atom/Icon";
import Styles from "./styles.module.scss";
import { useState } from "react";
import InputField from "@Molecule/InputField";
import InputDateField from "@Molecule/InputDateField";
import Dropdown from "@Atom/DropDown";
import {
  reVerificationEmailOrPhoneProfile,
  updateProfile,
} from "@Services/home";
import moment from "moment";
import useQuery from "@Hooks/useQuery";
import Spinner from "@Atom/Spinner";
import InputMonth from "@Atom/InputMonth";
import InputYear from "@Atom/InputYear";
import CheckboxJobPosting from "@Atom/CheckboxJobPosting";
import useWindowSize from "@Hooks/useWindowSize";

export default function EditInformationModal({
  data,
  sync,
  handleClose,
  setTrigger,
  trigger,
  isEditWorkExperiences = false,
  isAddWorkExperiences = false,
  isAddOtherFiles = false,
  isEditOtherFiles = false,
  isEditInformationContact = false,
  setHash = () => {},
  setPhoneNumberQuery = () => {},
  setTypeOtp = () => {},
}) {
  const query = useQuery();
  const { width } = useWindowSize();
  const [name, setName] = useState(data?.name || "");
  const [nik, setNik] = useState(data?.idNumber || "");
  const [dateOfBirth, setDateOfBirth] = useState(data?.dateOfBirth || "");
  const [gender, setGender] = useState(data?.gender || "");
  const [educationLevel, setEducationLevel] = useState(
    data?.CandidateAccountEducations?.length > 0
      ? data?.CandidateAccountEducations[0]?.level
      : ""
  );
  const [englishLevel, setEnglishLevel] = useState(
    data?.CandidateAccountSkills?.length
      ? data?.CandidateAccountSkills?.find(
          (el) => el?.skill === "Kemampuan Bahasa Inggris"
        )?.level || ""
      : ""
  );

  const [domicile, setDomicile] = useState(data?.domicile || "");
  const [address, setAddress] = useState(data?.address || "");

  const [position, setPosition] = useState(data?.jobTitle || "");
  const [company, setCompany] = useState(data?.companyName || "");
  const [startMonth, setStartMonth] = useState(data?.startMonth || "");
  const [startYear, setStartYear] = useState(data?.startYear || "");
  const [endYear, setEndYear] = useState(data?.endYear || "");
  const [endMonth, setEndMonth] = useState(data?.endMonth || "");
  const [isActive, setIsActive] = useState(data?.isEmployed || "");
  const [desc, setDesc] = useState(data?.description || "");

  const [type, setType] = useState("");

  const [otherType, setOtherType] = useState("");

  const [phoneNumber, setPhoneNumber] = useState(
    data?.data?.phoneNumber
      ? data?.data?.phoneNumber?.startsWith("62")
        ? data?.data?.phoneNumber?.slice(2)
        : data?.data?.phoneNumber
      : ""
  );
  const [email, setEmail] = useState(data?.data?.email || "");

  const [error, setError] = useState("");
  const [loading, setLoading] = useState("");

  // console.log(data);

  const handleSubmit = async (e, typeAction, mode) => {
    try {
      e.preventDefault();
      setLoading(true);
      const formData = new FormData();
      let dataTemp = {};

      switch (typeAction) {
        case "isEditInformationContact":
          if (mode === "email-verification") {
            dataTemp = {
              email: email,
            };
          } else if (mode === "phoneNumber-verification") {
            dataTemp = {
              phoneNumber: "62" + phoneNumber,
            };
          }
          break;
        case "isEditWorkExperiences":
        case "isAddWorkExperiences":
          if (typeAction === "isEditWorkExperiences") {
            formData?.append("workExperience[0][id]", data?.id || "");
          }

          formData?.append(
            "workExperience[0][action]",
            isAddWorkExperiences ? "CREATE" : "UPDATE"
          );
          formData?.append("workExperience[0][jobTitle]", position || "");
          formData?.append("workExperience[0][companyName]", company || "");
          formData?.append("workExperience[0][startMonth]", startMonth || "");
          formData?.append("workExperience[0][startYear]", startYear || "");
          formData?.append(
            "workExperience[0][endMonth]",
            isActive ? "" : endMonth || ""
          );
          formData?.append(
            "workExperience[0][endYear]",
            isActive ? "" : endYear || ""
          );

          formData?.append(
            "workExperience[0][isEmployed]",
            isActive ? "YES" : "NO"
          );
          formData?.append("workExperience[0][description]", desc);
          break;

        case "isAddOtherFiles":
        case "isEditOtherFiles":
          if (typeAction === "isEditOtherFiles") {
            formData?.append("accountDocuments[0][id]", data.id);
          }

          formData?.append(
            "accountDocuments[0][action]",
            isEditOtherFiles ? "UPDATE" : "CREATE"
          );

          formData?.append(
            "accountDocuments[0][label]",
            type?.toLowerCase() === "lainnya"
              ? otherType?.toUpperCase()
              : type?.toUpperCase()
          );

          formData?.append("accountDocuments[0][file]", data?.otherFiles);
          break;

        default:
          formData?.append("name", name || "");
          formData?.append("idNumber", nik || "");
          formData?.append(
            "dateOfBirth",
            dateOfBirth
              ? moment(new Date(dateOfBirth)).format("YYYY-MM-DD")
              : ""
          );

          formData?.append("gender", gender || "");
          formData?.append(
            "educationLevel[id]",
            data?.CandidateAccountEducations?.length > 0
              ? data?.CandidateAccountEducations[0]?.id
              : ""
          );
          formData?.append("educationLevel[level]", educationLevel || "");

          formData?.append(
            "englishLevel[id]",
            data?.CandidateAccountSkills?.length
              ? data?.CandidateAccountSkills?.find(
                  (el) => el?.skill === "Kemampuan Bahasa Inggris"
                )?.id || ""
              : ""
          );
          formData?.append("englishLevel[level]", englishLevel || "");
          formData?.append("domicile", domicile || "");
          formData?.append("address", address || "");
          break;
      }

      if (typeAction !== "isEditInformationContact") {
        const { response } = await updateProfile(formData);
        localStorage?.setItem(
          "nameUserLanding",
          response?.updatedAccount?.name
        );
        handleClose();
        setTrigger(Math.random());
      } else {
        const { response } = await reVerificationEmailOrPhoneProfile(dataTemp);
        setHash(response);
        setPhoneNumberQuery(
          mode === "email-verification" ? email : "62" + phoneNumber
        );
        setTypeOtp(mode);
        handleClose();
      }

      setLoading(false);
      setError("");
    } catch (error) {
      console.log(error);
      setLoading(false);
      setError(error.response?.data?.error);
    }
  };

  if (isEditWorkExperiences || isAddWorkExperiences) {
    return (
      <div className={Styles.container}>
        <div className={Styles.header}>
          <span>
            {isAddWorkExperiences ? "Tambah" : "Ubah"} Pengalaman Pekerjaan
          </span>
          <div onClick={handleClose}>
            <Icon icon={"cross"} size={24} color={"#0A0A0A"} />
          </div>
        </div>

        <div className={Styles.formBox}>
          <div className={Styles.form}>
            <span>Posisi</span>
            <InputField
              // errorText={`*Nama belum diisi`}
              // isError={validate && !formStepOne?.name}
              required
              placeholder={"Contoh: Security"}
              value={position}
              onChange={(e) => {
                const value = e.target.value;
                setPosition(value?.toUpperCase());
              }}
            />
          </div>

          <div className={Styles.form}>
            <span>Nama Perusahaan</span>
            <InputField
              required
              placeholder={"Contoh: Pt Maju Jaya"}
              value={company}
              onChange={(e) => {
                const value = e.target.value;
                setCompany(value?.toUpperCase());
              }}
            />
          </div>

          <div className={Styles.form}>
            <span>Mulai</span>
            <div className={Styles.dateBox}>
              <div className={Styles.date}>
                <InputMonth
                  // title={"Sampai Tahun"}
                  placeholder={"Pilih Bulan"}
                  value={startMonth}
                  setValue={(value) => {
                    setStartMonth(value);
                  }}
                  iconColor={"#0A0A0A"}
                  isArrow
                />
              </div>

              <div className={Styles.date}>
                <InputYear
                  // title={"Sampai Tahun"}

                  placeholder={"Pilih tahun"}
                  value={startYear}
                  setValue={(value) => {
                    setStartYear(value);
                  }}
                  iconColor={"#0A0A0A"}
                  isArrow
                />
              </div>
            </div>
          </div>

          <div className={Styles.form}>
            <span>Berakhir</span>
            <div className={Styles.dateBox}>
              <div className={Styles.date}>
                <InputMonth
                  // title={"Sampai Tahun"}
                  placeholder={"Pilih Bulan"}
                  value={endMonth}
                  setValue={(value) => {
                    setEndMonth(value);
                    setIsActive(false);
                  }}
                  iconColor={"#0A0A0A"}
                  isArrow
                />
              </div>

              <div className={Styles.date}>
                <InputYear
                  // title={"Sampai Tahun"}

                  placeholder={"Pilih tahun"}
                  value={endYear}
                  setValue={(value) => {
                    setEndYear(value);
                    setIsActive(false);
                  }}
                  iconColor={"#0A0A0A"}
                  isArrow
                />
              </div>
            </div>

            <div className={Styles.checkbox}>
              <CheckboxJobPosting
                checked={isActive}
                onChange={() => {
                  if (isActive) {
                    setIsActive(false);
                    setEndMonth("");
                    setEndYear("");
                  } else {
                    setIsActive(true);
                    setEndMonth("");
                    setEndYear("");
                  }
                }}
              />
              <span>Masih bekerja hingga saat ini</span>
            </div>
          </div>

          <div className={Styles.form}>
            <span>Deskripsi Pekerjaan</span>
            <InputField
              type={"desc"}
              required
              placeholder={"Masukkan deskripsi pekerjaan"}
              value={desc}
              onChange={(e) => {
                const value = e.target.value;
                setDesc(value?.toUpperCase());
              }}
            />
          </div>
        </div>

        <div className={Styles.buttonWrapper}>
          {error && <span>*{error}</span>}
          <div>
            <button
              disabled={loading}
              onClick={() => {
                handleClose();
              }}
            >
              Kembali
            </button>
            <button
              disabled={
                loading ||
                !position ||
                !company ||
                !startMonth ||
                !startYear ||
                (!isActive && !endMonth) ||
                (!isActive && !endYear) ||
                !desc
              }
              onClick={(e) => {
                if (isEditWorkExperiences) {
                  handleSubmit(e, "isEditWorkExperiences", "");
                } else if (isAddWorkExperiences) {
                  handleSubmit(e, "isAddWorkExperiences", "");
                }
              }}
            >
              {loading ? <Spinner /> : "Simpan"}
            </button>
          </div>
        </div>
      </div>
    );
  }

  if (isAddOtherFiles || isEditOtherFiles) {
    return (
      <div className={Styles.containerModal}>
        <div className={Styles.header}>
          <div className={Styles.top}>
            <span>Dokumen Opsional</span>
            <Icon
              icon={"cross"}
              size={24}
              className={Styles.iconCross}
              onClick={() => {
                handleClose();
                setType("");
                setOtherType("");
              }}
            />
          </div>

          <div className={Styles.bottom}>
            Silahkan pilih jenis file yang anda unggah sebelum melakukan
            penyimpanan file tersebut
          </div>
        </div>

        <div className={Styles.documentBox}>
          <div className={Styles.iconDocument}>
            <Icon icon={"document-text"} color={"#1571DE"} size={24} />
          </div>
          <span>{data?.otherFiles?.name || "-"}</span>
        </div>

        <div className={Styles.dropdownOptional}>
          <span>Pilih Jenis Dokumen</span>
          <div className={Styles.dropdown}>
            <Dropdown
              value={type}
              setValue={setType}
              placeholder={"Belum Dipilih"}
              options={[
                ...(sync
                  ?.filter(
                    (sync) =>
                      sync?.type === "Document" &&
                      !data?.data?.CandidateAccountDocuments?.some(
                        (d) =>
                          d?.label?.toLowerCase() === sync?.value?.toLowerCase()
                      )
                  )
                  ?.map((el, idx) => ({
                    id: idx,
                    name: el?.value,
                  })) || []),
                { id: -1, name: "Lainnya" }, // Data hardcoded
              ]}
              autoClose
              overFlow
              top={width > 768 ? false : true}
            />
          </div>
        </div>

        {type?.toLowerCase() === "lainnya" && (
          <div className={Styles.dropdownOptional}>
            <span>Tuliskan Nama File</span>
            <div className={Styles.dropdown}>
              <InputField
                value={otherType}
                onChange={(e) => setOtherType(e?.target?.value)}
                placeholder={"Nama File"}
                required
              />
            </div>
          </div>
        )}

        <div className={Styles.buttonWrapper}>
          {error && <span>*{error}</span>}
          <div>
            <button
              disabled={loading}
              onClick={() => {
                handleClose();
              }}
            >
              Kembali
            </button>
            <button
              disabled={
                loading ||
                +query?.get("tr") !== +trigger ||
                !type ||
                !data?.otherFiles ||
                (type && type?.toLowerCase() === "lainnya" && !otherType)
              }
              onClick={(e) => {
                if (isAddOtherFiles) {
                  handleSubmit(e, "isAddOtherFiles", "");
                } else if (isEditOtherFiles) {
                  handleSubmit(e, "isEditOtherFiles", "");
                }
              }}
            >
              {loading || +query?.get("tr") !== +trigger ? (
                <Spinner />
              ) : (
                "Simpan"
              )}
            </button>
          </div>
        </div>
      </div>
    );
  }

  if (isEditInformationContact) {
    return (
      <div className={Styles.container}>
        <div className={Styles.header}>
          <span>Ubah Informasi Kontak</span>
          <div onClick={handleClose}>
            <Icon icon={"cross"} size={24} color={"#0A0A0A"} />
          </div>
        </div>

        <div className={Styles.formBox}>
          <div className={Styles.form}>
            <span>{data?.type === "email" ? "Email" : "Nomor Whatsapp"}</span>

            {data?.type === "email" ? (
              <InputField
                // errorText={`*Nama belum diisi`}
                // isError={validate && !formStepOne?.name}
                required
                placeholder={"Masukkan Email"}
                value={email}
                onChange={(e) => {
                  const value = e.target.value.toLowerCase();
                  // Konversi ke huruf kecil dan hilangkan spasi
                  if (/^[a-zA-Z0-9@._]*$/.test(value)) {
                    setEmail(value);
                  }
                }}
              />
            ) : (
              <InputField
                type={"phone"}
                // errorText={`*Nama belum diisi`}
                // isError={validate && !formStepOne?.name}
                required
                placeholder={"Masukkan Nomor"}
                value={phoneNumber}
                onChange={(e) => {
                  setPhoneNumber(e?.target?.value);
                }}
              />
            )}
          </div>
        </div>

        <div className={Styles.buttonWrapper}>
          {error && <span>*{error}</span>}
          <div>
            <button
              disabled={loading}
              onClick={() => {
                handleClose();
              }}
            >
              Kembali
            </button>
            <button
              disabled={
                (data?.type === "email" && !email) ||
                (data?.type === "phoneNumber" && !phoneNumber)
              }
              onClick={(e) => {
                if (data?.type === "email") {
                  handleSubmit(
                    e,
                    "isEditInformationContact",
                    "email-verification"
                  );
                } else {
                  handleSubmit(
                    e,
                    "isEditInformationContact",
                    "phoneNumber-verification"
                  );
                }
              }}
            >
              {loading ? <Spinner /> : "Simpan"}
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <span>Ubah Informasi Personal</span>
        <div onClick={handleClose}>
          <Icon icon={"cross"} size={24} color={"#0A0A0A"} />
        </div>
      </div>

      <div className={Styles.formBox}>
        <div className={Styles.form}>
          <span>Nama</span>
          <InputField
            // errorText={`*Nama belum diisi`}
            // isError={validate && !formStepOne?.name}
            required
            placeholder={"Masukkan Nama"}
            value={name}
            onChange={(e) => {
              const value = e.target.value;
              if (/^[a-zA-Z\s.,]*$/.test(value)) {
                setName(value?.toUpperCase());
              }
            }}
          />
        </div>

        <div className={Styles.form}>
          <span>NIK</span>
          <InputField
            isError={nik?.length !== 16}
            errorText="NIK harus 16 digit"
            required
            placeholder={"Masukkan NIK"}
            value={nik}
            onChange={(e) => {
              const value = e.target.value;
              const re = /^[0-9\b]+$/;
              if (re.test(value) || value === "") {
                if (value?.length < 17) {
                  setNik(value);
                }
              }
            }}
          />
        </div>

        <div className={Styles.form}>
          <span>Tanggal Lahir</span>
          <InputDateField
            // errorText={`*Tanggal lahir belum diisi`}
            // isError={validate && !formStepOne?.dateOfBirth}
            placeholder={"Pilih tanggal lahir"}
            value={dateOfBirth}
            setValue={(value) => {
              setDateOfBirth(value);
            }}
          />
        </div>

        <div className={Styles.form}>
          <span>Jenis Kelamin</span>
          <Dropdown
            placeholder={"Pilih jenis kelamin"}
            options={
              sync?.length > 0
                ? sync
                    ?.filter((e) => e?.type === "Gender")
                    ?.map((item) => {
                      return {
                        id: item?.id,
                        name: item?.value,
                      };
                    }) || []
                : []
            }
            value={gender}
            setValue={(e) => setGender(e)}
            autoClose

            //  iconColor={iconColor}
          />
        </div>

        <div className={Styles.form}>
          <span>Pendidikan Terakhir</span>
          <Dropdown
            // isError={validate && !formStepTwo.educationLevel}
            // errorText="Pendidikan belum dipilih"
            placeholder={"Pilih pendidikan terakhir"}
            options={
              sync?.length > 0
                ? sync
                    ?.filter((e) => e?.type === "Education")
                    ?.map((item) => {
                      return {
                        id: item?.id,
                        name: item?.value,
                      };
                    }) || []
                : []
            }
            value={educationLevel}
            setValue={(e) => setEducationLevel(e)}
            autoClose

            //  iconColor={iconColor}
          />
        </div>

        <div className={Styles.form}>
          <span>Kemampuan Bahasa Inggris</span>
          <Dropdown
            // isError={validate && !formStepTwo.englishLevel}
            // errorText="Kemampuan bahasa inggris belum dipilih"
            placeholder={"Pilih kemampuan bahasa inggris"}
            options={
              sync?.length > 0
                ? sync
                    ?.filter((e) => e?.type === "English Level")
                    ?.map((item) => {
                      return {
                        id: item?.id,
                        name: item?.value,
                      };
                    }) || []
                : []
            }
            value={englishLevel}
            setValue={(e) => setEnglishLevel(e)}
            autoClose

            //  iconColor={iconColor}
          />
        </div>

        <div className={Styles.form}>
          <span>Alamat Domisili (Tempat Tinggal Sekarang)</span>
          <InputField
            // isError={validate && !formStepTwo.addressDomicilie}
            // errorText="Alamat belum dipilih"
            type={"address"}
            // errorText={`*Nama belum diisi`}
            // isError={validate && !formStepOne?.name}
            required
            placeholder={"Masukkan alamat domisili"}
            value={domicile}
            onChange={(e) => {
              const value = e.target.value;
              setDomicile(value?.toUpperCase());
            }}
          />
        </div>

        <div className={Styles.form}>
          <span>Alamat Sesuai KTP</span>
          <InputField
            // isError={validate && !formStepTwo.address}
            // errorText="Alamat belum dipilih"
            type={"address"}
            // errorText={`*Nama belum diisi`}
            // isError={validate && !formStepOne?.name}
            required
            placeholder={"Masukkan alamat sesuai KTP"}
            value={address}
            onChange={(e) => {
              const value = e.target.value;
              setAddress(value?.toUpperCase());
            }}
          />
        </div>
      </div>

      <div className={Styles.buttonWrapper}>
        {error && <span>*{error}</span>}
        <div>
          <button
            disabled={loading}
            onClick={() => {
              handleClose();
            }}
          >
            Kembali
          </button>
          <button
            disabled={
              !name ||
              !nik ||
              !dateOfBirth ||
              !gender ||
              !educationLevel ||
              !englishLevel ||
              !domicile ||
              !address ||
              loading ||
              +query?.get("tr") !== +trigger ||
              (nik && nik?.length !== 16)
            }
            onClick={(e) => {
              handleSubmit(e, "others", "");
            }}
          >
            {loading ? <Spinner /> : "Simpan"}
          </button>
        </div>
      </div>
    </div>
  );
}
