import Icon from "@Atom/Icon";
import Styles from "./styles.module.scss";

export default function Toast({
  isSent,
  label,
  icon = "",
  color = "",
  bg = "",
}) {
  return (
    <>
      {isSent && <div className={Styles.overlay}></div>}
      <div
        className={`${Styles.notification} ${isSent && Styles.show}`}
        style={{
          backgroundColor: bg ? bg : "#27bb83",
          color: color ? color : "#fff",
        }}
      >
        <Icon
          icon={icon ? icon : "check-circle-2"}
          size={20}
          color={color ? color : "#fff"}
        />
        {label}
      </div>
    </>
  );
}
