import Icon from "@Atom/Icon";
import Styles from "./styles.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useMemo, useRef, useState } from "react";
import Images from "@Theme/Images";
import { fileBaseUrl } from "@Config/api";
import moment from "moment";
import ModalWrapper from "@Atom/ModalWrapper";
import EditInformationModal from "./EditInformationModal";
import useQuery from "@Hooks/useQuery";
import HomeFooter from "../Business/HomeFooter";
import { updateProfile, userLogin } from "@Services/home";
import Spinner from "@Atom/Spinner";
import OTPConfirmationModal from "@Molecule/_modal/OTPConfirmationModal";
import useWindowSize from "@Hooks/useWindowSize";
import HeaderComponent from "@Molecule/Header";

export default function SettingProfileUser({ data, sync }) {
  const user = {
    name: localStorage.getItem("username"),
    position: localStorage.getItem("role"),
    accessToken: localStorage.getItem("accessToken"),
    accessTokenUserLanding: localStorage?.getItem("accessTokenUserLanding"),
    nameUserLanding: localStorage?.getItem("nameUserLanding"),
    phoneNumberUserLanding: localStorage?.getItem("phoneNumberUserLanding"),
    emailUserLanding: localStorage?.getItem("emailUserLanding"),
    dataProfile: localStorage?.getItem("dataProfile"),
    profilePicture: localStorage?.getItem("profilePictUserLanding"),
    // name: sessionStorage.getItem("username"),
    // position: sessionStorage.getItem("role"),
    // accessToken: sessionStorage.getItem("accessToken"),
  };

  // console.log(process.env.REACT_APP_CLIENT_ID);

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { width } = useWindowSize();
  const query = useQuery();

  const [trigger, setTrigger] = useState(query?.get("tr") || "");
  const [hash, setHash] = useState(query?.get("h") || "");
  const [phoneNumber, setPhoneNumber] = useState(query?.get("p") || "");
  const [typeOtp, setTypeOtp] = useState(query?.get("ty") || "");

  useEffect(() => {
    navigate(
      `${pathname}?tr=${trigger || ""}&h=${hash || ""}&p=${
        phoneNumber || ""
      }&ty=${typeOtp || ""}`
    );
  }, [trigger, pathname, navigate, hash, phoneNumber, typeOtp]);

  const [editPhotoProfile, setEditPhotoProfile] = useState(false);
  const [editInformationPersonal, setEditInformationPersonal] = useState(false);
  const [editInformationContact, setEditInformationContact] = useState(null);
  const [editWorkExperience, setEditWorkExperience] = useState(null);
  const [addWorkExperience, setAddWorkExperience] = useState(false);
  const [addOtherFiles, setAddOtherFiles] = useState(null);
  const [editOtherFiles, setEditOtherFiles] = useState(null);

  const inputCvRef = useRef();
  const inputPhotoProfileRef = useRef();
  const inputOtherRef = useRef();
  const inputEditOtherRef = useRef();

  // console.log(data, "test");

  const optionsMenu = [
    {
      id: 1,
      name: "Profil Saya",
      icon: "user-circle",
    },
    // {
    //   id: 2,
    //   name: "Pekerjaan Dilamar",
    //   icon: "contracted",
    // },
    // {
    //   id: 3,
    //   name: "Loker Disimpan",
    //   icon: "archive",
    // },
  ];

  // const { state } = useLocation();

  const defaultSelectedMenu =
    sessionStorage?.getItem("selectedMenuSettingLanding") || "Profil Saya";
  const [selectedMenu, setSelectedMenu] = useState(
    defaultSelectedMenu || "Profil Saya"
  );

  useEffect(() => {
    sessionStorage?.setItem("selectedMenuSettingLanding", selectedMenu);
  }, [selectedMenu]);

  const [loading, setLoading] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (e, payload, type) => {
    try {
      e?.preventDefault();
      if (type === "email" || type === "phoneNumber") {
        setLoading(type);

        const { response } = await userLogin({
          emailOrPhoneNUmber: payload,
          type: type,
        });
        setLoading("");
        // setTrigger(Math.random());
        setHash(response);
        setPhoneNumber(payload);
        setError("");
        setTypeOtp(type);
      } else {
        const formData = new FormData();
        if (type === "CV") {
          setLoading(type);
          if (
            payload?.CandidateAccountDocuments?.length > 0 &&
            payload?.CandidateAccountDocuments?.find((el) => el?.label === "CV")
          ) {
            formData?.append(
              "accountDocuments[0][id]",
              payload?.CandidateAccountDocuments?.find(
                (el) => el?.label === "CV"
              )?.id || ""
            );
          }

          formData?.append(
            "accountDocuments[0][action]",
            payload?.CandidateAccountDocuments?.length > 0 &&
              payload?.CandidateAccountDocuments?.find(
                (el) => el?.label === "CV"
              )
              ? "UPDATE"
              : "CREATE"
          );
          formData?.append("accountDocuments[0][label]", type || "");
          formData?.append(
            "accountDocuments[0][file]",
            e?.target?.files[0] || ""
          );
        } else if (type === "PROFILE") {
          setLoading(type);
          formData?.append("profilePic", e?.target?.files[0] || "");
        } else if (type === "Otherdocument") {
          setLoading(payload?.id);
          formData?.append("accountDocuments[0][id]", payload?.id || "");
          formData?.append("accountDocuments[0][action]", "DELETE" || "");
        } else {
          setLoading(payload?.id);
          formData?.append("workExperience[0][id]", payload?.id || "");
          formData?.append("workExperience[0][action]", "DELETE" || "");
        }

        const { response } = await updateProfile(formData);
        localStorage?.setItem(
          "profilePictUserLanding",
          response?.updatedAccount?.profilePic || ""
        );
        setLoading("");
        setTrigger(Math.random());
        setError("");
      }
    } catch (error) {
      console.log(error);
      setLoading("");
      setError(error.response?.data?.error);
    }
  };

  const content = useMemo(() => {
    switch (selectedMenu) {
      case "Profil Saya":
        return (
          <div className={Styles.right}>
            <div className={Styles.profileBox}>
              <div
                className={Styles.images}
                onMouseEnter={() => {
                  setEditPhotoProfile(true);
                }}
                onMouseLeave={() => {
                  setEditPhotoProfile(false);
                }}
                onClick={() => inputPhotoProfileRef.current.click()}
              >
                <input
                  accept="image/jpeg, image/png, image/jpg" // Mengizinkan JPG, JPEG, dan PNG
                  type="file"
                  ref={inputPhotoProfileRef}
                  name="file"
                  onChange={(e) => {
                    if (e?.target?.files?.length > 0) {
                      const allowedTypes = [
                        "image/jpeg",
                        "image/png",
                        "image/jpg",
                      ];

                      if (allowedTypes.includes(e?.target?.files[0].type)) {
                        handleSubmit(e, data, "PROFILE");
                      }
                    }
                    e.target.value = "";
                  }}
                  hidden
                />
                {editPhotoProfile ||
                loading === "PROFILE" ||
                +trigger !== +query?.get("tr") ? (
                  <div className={Styles.editBox}>
                    {loading === "PROFILE" || +trigger !== +query?.get("tr") ? (
                      <Spinner />
                    ) : (
                      <Icon icon={"edit"} color={"#FFFFFF"} size={24} />
                    )}
                  </div>
                ) : null}
                <img
                  src={
                    data?.profilePic
                      ? data?.profilePic?.includes("https://")
                        ? data?.profilePic
                        : fileBaseUrl + data?.profilePic
                      : Images.AVA_DEFAULT
                  }
                  alt=""
                />
              </div>
              <div className={Styles.name}>
                <span>{data?.name || "-"}</span>
              </div>
            </div>

            <div className={Styles.informationBox}>
              <div className={Styles.headerInfo}>
                <span className={Styles.leftInfo}>
                  <span>Informasi Kontak</span>

                  {!data?.email || !data?.phoneNumber ? (
                    <div>
                      <Icon
                        icon={"information-solid"}
                        size={20}
                        color={"#F28609"}
                      />
                      <span>Lengkapi data diri kamu sekarang!</span>
                    </div>
                  ) : null}
                </span>
              </div>

              <div className={Styles.info}>
                <div>
                  <div className={Styles.listInfo}>
                    <div className={Styles.icon}>
                      <Icon
                        icon={"email-outline"}
                        size={24}
                        color={"#1571DE"}
                      />
                    </div>

                    <div className={Styles.titleInfo}>
                      <div>
                        <span>
                          Email{" "}
                          <div
                            onClick={() => {
                              setEditInformationContact("email");
                            }}
                          >
                            <Icon icon={"edit"} size={16} color={"#1571DE"} />
                          </div>
                        </span>

                        {!data?.isEmailVerify && (
                          <div>
                            <Icon
                              icon={"information-solid"}
                              size={16}
                              color={"#FF3E13"}
                            />
                            <span>Belum terverifikasi</span>
                          </div>
                        )}
                      </div>

                      <span>{data?.email || "-"}</span>
                    </div>
                  </div>

                  {!data?.isEmailVerify && (
                    <div className={Styles.button}>
                      <button
                        disabled={loading === "email"}
                        onClick={(e) => {
                          handleSubmit(e, data?.email, "email");
                        }}
                      >
                        {loading === "email" ? <Spinner /> : "Verifikasi Email"}
                      </button>
                    </div>
                  )}
                </div>
                <div>
                  <div className={Styles.listInfo}>
                    <div className={Styles.icon}>
                      <Icon icon={"whatsapp"} size={24} color={"#1571DE"} />
                    </div>

                    <div className={Styles.titleInfo}>
                      <div>
                        <span>
                          Nomor Whatsapp{" "}
                          <div
                            onClick={() => {
                              setEditInformationContact("phoneNumber");
                            }}
                          >
                            <Icon icon={"edit"} size={16} color={"#1571DE"} />
                          </div>
                        </span>

                        {!data?.isPhoneNumberVerify && (
                          <div>
                            <Icon
                              icon={"information-solid"}
                              size={16}
                              color={"#FF3E13"}
                            />
                            <span>Belum terverifikasi</span>
                          </div>
                        )}
                      </div>

                      {data?.phoneNumber ? (
                        <span>+{data?.phoneNumber || "-"}</span>
                      ) : (
                        <span>Belum ada nomor terdaftar</span>
                      )}
                    </div>
                  </div>
                  {!data?.isPhoneNumberVerify && data?.phoneNumber && (
                    <div className={Styles.button}>
                      <button
                        disabled={loading === "phoneNumber"}
                        onClick={(e) => {
                          handleSubmit(e, data?.phoneNumber, "phoneNumber");
                        }}
                      >
                        {loading === "phoneNumber" ? (
                          <Spinner />
                        ) : (
                          "Verifikasi Nomor"
                        )}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className={Styles.informationPersonalBox}>
              <div className={Styles.headerInfo}>
                <div className={Styles.leftInfo}>
                  <span>Informasi personal</span>
                  {!data?.idNumber ||
                  !data?.dateOfBirth ||
                  !data?.gender ||
                  data?.CandidateAccountEducations?.length === 0 ||
                  data?.CandidateAccountSkills?.length === 0 ||
                  (data?.CandidateAccountSkills?.length > 0 &&
                    !data?.CandidateAccountSkills?.find(
                      (el) => el?.skill === "Kemampuan Bahasa Inggris"
                    )?.level) ||
                  !data?.domicile ||
                  !data?.address ? (
                    <div>
                      <Icon
                        icon={"information-solid"}
                        size={20}
                        color={"#F28609"}
                      />
                      <span>Lengkapi data diri kamu sekarang!</span>
                    </div>
                  ) : null}
                </div>

                <div
                  className={Styles.rightInfo}
                  onClick={() => {
                    setEditInformationPersonal(true);
                  }}
                >
                  <div className={Styles.icon}>
                    <Icon icon={"edit"} size={20} color={"#1571DE"} />
                  </div>
                  <span>Ubah Data</span>
                </div>
              </div>

              <div className={Styles.contentInfo}>
                <div className={Styles.listBox}>
                  <div className={Styles.list}>
                    <div className={Styles.icon}>
                      <Icon icon={"nik"} size={24} color={"#1571DE"} />
                    </div>

                    <div className={Styles.desc}>
                      <span>NIK</span>
                      <span>{data?.idNumber || "-"}</span>
                    </div>
                  </div>

                  <div className={Styles.list}>
                    <div className={Styles.icon}>
                      <Icon
                        icon={"calender-outline"}
                        size={24}
                        color={"#1571DE"}
                      />
                    </div>

                    <div className={Styles.desc}>
                      <span>Tanggal Lahir</span>
                      <span>
                        {data?.dateOfBirth
                          ? moment(new Date(data?.dateOfBirth))?.format(
                              "DD MMMM YYYY"
                            )
                          : ""}
                      </span>
                    </div>
                  </div>
                </div>

                <div className={Styles.listBox}>
                  <div className={Styles.list}>
                    <div className={Styles.icon}>
                      <Icon icon={"gender"} size={24} color={"#1571DE"} />
                    </div>

                    <div className={Styles.desc}>
                      <span>Jenis Kelamin</span>
                      <span>{data?.gender || "-"}</span>
                    </div>
                  </div>

                  <div className={Styles.list}>
                    <div className={Styles.icon}>
                      <Icon icon={"graduation"} size={24} color={"#1571DE"} />
                    </div>

                    <div className={Styles.desc}>
                      <span>Pendidikan Terakhir</span>
                      <span>
                        {data?.CandidateAccountEducations?.length > 0
                          ? data?.CandidateAccountEducations[0]?.level
                          : "-"}
                      </span>
                    </div>
                  </div>
                </div>

                <div className={Styles.listBox}>
                  <div className={Styles.list}>
                    <div className={Styles.icon}>
                      <Icon icon={"language"} size={24} color={"#1571DE"} />
                    </div>

                    <div className={Styles.desc}>
                      <span>Kemampuan Bahasa Inggris</span>
                      <span>
                        {data?.CandidateAccountSkills?.length
                          ? data?.CandidateAccountSkills?.find(
                              (el) => el?.skill === "Kemampuan Bahasa Inggris"
                            )?.level || "-"
                          : "-"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className={`${Styles.contentInfo} ${Styles.noBorder}`}>
                <div className={Styles.listBox}>
                  <div className={Styles.list}>
                    <div className={Styles.icon}>
                      <Icon
                        icon={"location-profile"}
                        size={24}
                        color={"#1571DE"}
                      />
                    </div>

                    <div className={Styles.desc}>
                      <span>Domisili (Tempat Tinggal Sekarang)</span>
                      <span>{data?.domicile || "-"}</span>
                    </div>
                  </div>

                  <div className={Styles.list}>
                    <div className={Styles.icon}>
                      <Icon
                        icon={"location-point"}
                        size={24}
                        color={"#1571DE"}
                      />
                    </div>

                    <div className={Styles.desc}>
                      <span>Alamat Lengkap</span>
                      <span>{data?.address || "-"}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={Styles.informationPersonalBox}>
              <div className={Styles.headerInfo}>
                <div className={Styles.leftInfo}>
                  <span>CV</span>
                  {data?.CandidateAccountDocuments?.length === 0 ||
                  (data?.CandidateAccountDocuments?.length > 0 &&
                    !data?.CandidateAccountDocuments?.find(
                      (el) => el?.label === "CV"
                    )?.url) ? (
                    <div>
                      <Icon
                        icon={"information-solid"}
                        size={20}
                        color={"#F28609"}
                      />
                      <span>Unggah CV mu sekarang!</span>
                    </div>
                  ) : null}
                </div>

                {data?.CandidateAccountDocuments?.length > 0 &&
                  data?.CandidateAccountDocuments?.find(
                    (el) => el?.label === "CV"
                  ) && (
                    <div className={Styles.dateInfo}>
                      Diunggah pada{" "}
                      {data?.CandidateAccountDocuments?.find(
                        (el) => el?.label === "CV"
                      )?.createdAt
                        ? moment(
                            new Date(
                              data?.CandidateAccountDocuments?.find(
                                (el) => el?.label === "CV"
                              )?.createdAt
                            )
                          ).format("DD MMMM YYYY")
                        : "-"}
                    </div>
                  )}
              </div>

              <div className={Styles.cvBox}>
                <div className={Styles.leftCv}>
                  <div className={Styles.icon}>
                    <Icon icon={"report"} size={24} color={"#1571DE"} />
                  </div>
                  <span>
                    {data?.CandidateAccountDocuments?.length > 0
                      ? data?.CandidateAccountDocuments?.find(
                          (el) => el?.label === "CV"
                        )?.url || "Belum ada CV diunggah"
                      : "Belum ada CV diunggah"}
                  </span>
                </div>

                <div className={Styles.rightCv}>
                  <button
                    disabled={
                      loading === "CV" || +trigger !== +query?.get("tr")
                    }
                    onClick={() => inputCvRef.current.click()}
                  >
                    <Icon
                      icon={"upload"}
                      size={20}
                      className={Styles.iconUUpload}
                    />
                    {loading === "CV" || +trigger !== +query?.get("tr")
                      ? "Loading..."
                      : data?.CandidateAccountDocuments?.length > 0 &&
                        data?.CandidateAccountDocuments?.find(
                          (el) => el?.label === "CV"
                        )?.url
                      ? "Ganti CV"
                      : " Unggah CV"}

                    <input
                      accept="application/pdf" // Membatasi hanya file PDF
                      type="file"
                      ref={inputCvRef}
                      name="file"
                      onChange={(e) => {
                        if (e?.target?.files?.length > 0) {
                          if (
                            e?.target?.files?.[0]?.type === "application/pdf"
                          ) {
                            handleSubmit(e, data, "CV");
                          }
                        }
                        e.target.value = "";
                      }}
                      hidden
                    />
                  </button>
                </div>
              </div>
            </div>

            <div className={Styles.informationPersonalBox}>
              <div className={Styles.headerInfo}>
                <div className={Styles.leftInfo}>
                  <span>Dokumen Lainnya</span>
                </div>
                {data?.CandidateAccountDocuments?.length > 0 &&
                  data?.CandidateAccountDocuments?.filter(
                    (item) => item?.label !== "CV"
                  )?.length > 0 && (
                    <div
                      className={Styles.rightInfo}
                      style={{
                        width: "250px",
                      }}
                      onClick={() => inputOtherRef.current.click()}
                    >
                      <div className={Styles.icon}>
                        <Icon
                          icon={"circle-plus"}
                          size={20}
                          color={"#1571DE"}
                        />
                      </div>
                      <span>Tambah Dokumen</span>
                      <input
                        accept="application/pdf" // Membatasi hanya file PDF
                        type="file"
                        ref={inputOtherRef}
                        name="file"
                        onChange={(e) => {
                          if (e?.target?.files?.length > 0) {
                            if (
                              e?.target?.files?.[0]?.type === "application/pdf"
                            ) {
                              setAddOtherFiles(e?.target?.files?.[0]);
                            }
                          }

                          e.target.value = "";
                        }}
                        hidden
                      />
                    </div>
                  )}
              </div>

              {data?.CandidateAccountDocuments?.length > 0 &&
              data?.CandidateAccountDocuments?.filter(
                (item) => item?.label !== "CV"
              )?.length > 0 ? (
                <div className={Styles.otherDoc}>
                  {data?.CandidateAccountDocuments?.filter(
                    (item) => item?.label !== "CV"
                  )?.map((el, idx) => {
                    return (
                      <div className={Styles.doc} key={idx}>
                        <span>{el?.label || "-"}</span>
                        <div>
                          <div className={Styles.leftDoc}>
                            <div className={Styles.icon}>
                              <Icon icon={"file"} size={24} color={"#1571DE"} />
                            </div>
                            <span>{el?.url || "-"}</span>
                          </div>

                          <div className={Styles.rightDoc}>
                            <div>
                              <Icon
                                icon={"edit"}
                                size={24}
                                color={"#1571DE"}
                                onClick={() =>
                                  inputEditOtherRef?.current?.click()
                                }
                              />
                              <input
                                key={Math.random()} // Key dinamis untuk mereset elemen input
                                accept="application/pdf"
                                type="file"
                                ref={inputEditOtherRef}
                                name="file"
                                onChange={(e) => {
                                  if (e?.target?.files?.length > 0) {
                                    if (
                                      e?.target?.files?.[0]?.type ===
                                      "application/pdf"
                                    ) {
                                      setEditOtherFiles({
                                        data: el,
                                        file: e?.target?.files?.[0],
                                      });
                                    }
                                  }
                                  e.target.value = "";
                                }}
                                hidden
                              />
                            </div>

                            <div>
                              {loading === el?.id ||
                              +trigger !== +query?.get("tr") ? (
                                <Spinner />
                              ) : (
                                <Icon
                                  icon={"delete"}
                                  size={24}
                                  color={"#FF3E13"}
                                  onClick={(e) => {
                                    if (!loading) {
                                      handleSubmit(e, el, "Otherdocument");
                                    }
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className={Styles.emptyDoc}>
                  <span>Belum ada dokumen lain yang ditambahkan</span>
                  <div>
                    <button onClick={() => inputOtherRef.current.click()}>
                      <Icon
                        icon={"circle-plus"}
                        size={20}
                        className={Styles.iconUUpload}
                      />
                      Tambah dokumen lainnya
                      <input
                        accept="application/pdf" // Membatasi hanya file PDF
                        type="file"
                        ref={inputOtherRef}
                        name="file"
                        onChange={(e) => {
                          if (e?.target?.files?.length > 0) {
                            if (
                              e?.target?.files?.[0]?.type === "application/pdf"
                            ) {
                              setAddOtherFiles(e?.target?.files?.[0]);
                            }
                          }

                          e.target.value = "";
                        }}
                        hidden
                      />
                    </button>
                  </div>
                </div>
              )}
            </div>

            <div className={Styles.informationPersonalBox}>
              <div className={Styles.headerInfo}>
                <div className={Styles.leftInfo}>
                  <span>Pengalaman Bekerja</span>
                </div>
                {data?.CandidateAccountWorkExperiences?.length > 0 && (
                  <div
                    onClick={() => {
                      setAddWorkExperience(true);
                    }}
                    className={Styles.rightInfo}
                    style={{
                      width: "250px",
                    }}
                  >
                    <div className={Styles.icon}>
                      <Icon icon={"circle-plus"} size={20} color={"#1571DE"} />
                    </div>
                    <span>Tambah Pengalaman</span>
                  </div>
                )}
              </div>

              {data?.CandidateAccountWorkExperiences?.length > 0 ? (
                <div className={Styles.otherDoc}>
                  {data?.CandidateAccountWorkExperiences?.map((el, idx) => {
                    return (
                      <div className={Styles.doc} key={idx}>
                        <div>
                          <div className={Styles.descExperiences}>
                            <span>{el?.jobTitle || "-"}</span>
                            <div>
                              <Icon
                                icon={"building"}
                                color={"#0A0A0A"}
                                size={18}
                              />
                              <span>{el?.companyName || "-"}</span> •{" "}
                              <Icon
                                icon={"calendar-v2"}
                                color={"#0A0A0A"}
                                size={18}
                              />
                              {`${el?.startMonth || "-"} ${
                                el?.startYear || "-"
                              }`}{" "}
                              -{" "}
                              {el?.isEmployed
                                ? "Saat ini"
                                : `${el?.endMonth} ${el?.endYear}`}
                            </div>
                          </div>

                          <div className={Styles.rightDoc}>
                            <div>
                              <Icon
                                icon={"edit"}
                                size={24}
                                color={"#1571DE"}
                                onClick={() => {
                                  setEditWorkExperience(el);
                                }}
                              />
                            </div>
                            {loading === el?.id ||
                            +trigger !== +query?.get("tr") ? (
                              <Spinner />
                            ) : (
                              <div>
                                <Icon
                                  icon={"delete"}
                                  size={24}
                                  color={"#FF3E13"}
                                  onClick={(e) => {
                                    if (!loading) {
                                      handleSubmit(e, el, "experiences");
                                    }
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className={Styles.emptyDoc}>
                  <span>Belum ada pengalaman pekerjaan yang ditambahkan</span>
                  <div>
                    <button
                      onClick={() => {
                        setAddWorkExperience(true);
                      }}
                    >
                      <Icon
                        icon={"bag-profile"}
                        size={20}
                        className={Styles.iconUUpload}
                      />
                      Tambah Pengalaman
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        );

      default:
        return null;
    }
  }, [
    selectedMenu,
    editPhotoProfile,
    data,
    trigger,
    query,
    loading,
    handleSubmit,
    inputCvRef,
    inputPhotoProfileRef,
    inputOtherRef,
    inputEditOtherRef,
  ]);

  useEffect(() => {
    if (
      editInformationPersonal ||
      editWorkExperience ||
      addWorkExperience ||
      addOtherFiles ||
      query?.get("h")
    ) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.removeProperty("overflow");
    }
  }, [
    editInformationPersonal,
    editWorkExperience,
    addWorkExperience,
    addOtherFiles,
    query,
  ]);

  return (
    <>
      {width <= 820 && (
        <HeaderComponent
          user={user}
          selectedMenu={selectedMenu}
          setSelectedMenu={setSelectedMenu}
        />
      )}
      <div className={Styles.container}>
        <div className={Styles.header}>
          <div className={Styles.inner}>
            <div
              className={Styles.routes}
              onClick={() => {
                navigate("/");
              }}
            >
              <div>
                <Icon
                  icon={"arrow-left"}
                  size={16}
                  // color={"#FFFFFF"}
                  className={Styles.iconBack}
                />
              </div>
              <span>Kembali Ke Beranda</span>
            </div>
          </div>
        </div>

        <div className={Styles.content}>
          <div className={Styles.left}>
            <span>Menu</span>
            <div>
              {optionsMenu?.map((el, idx) => (
                <div
                  key={idx}
                  className={`${Styles.listMenu} ${
                    selectedMenu === el?.name && Styles.active
                  }`}
                  onClick={() => {
                    if (selectedMenu !== el?.name) {
                      setSelectedMenu(el?.name);
                    }
                  }}
                >
                  <div>
                    <Icon icon={el?.icon} size={20} className={Styles.icon} />
                  </div>
                  <span>{el?.name}</span>
                </div>
              ))}
            </div>
          </div>
          {content}
        </div>

        {editInformationPersonal && (
          <ModalWrapper
            show={editInformationPersonal}
            handleClose={() => {}}
            isLandingPage
          >
            <EditInformationModal
              data={data}
              sync={sync}
              handleClose={() => setEditInformationPersonal(false)}
              setTrigger={setTrigger}
              trigger={trigger}
            />
          </ModalWrapper>
        )}

        {editWorkExperience && (
          <ModalWrapper
            show={editWorkExperience}
            handleClose={() => {}}
            isLandingPage
          >
            <EditInformationModal
              data={editWorkExperience}
              sync={sync}
              handleClose={() => setEditWorkExperience(null)}
              setTrigger={setTrigger}
              trigger={trigger}
              isEditWorkExperiences
            />
          </ModalWrapper>
        )}

        {addWorkExperience && (
          <ModalWrapper
            show={addWorkExperience}
            handleClose={() => {}}
            isLandingPage
          >
            <EditInformationModal
              data={null}
              sync={sync}
              handleClose={() => setAddWorkExperience(false)}
              setTrigger={setTrigger}
              trigger={trigger}
              isAddWorkExperiences
            />
          </ModalWrapper>
        )}

        {addOtherFiles && (
          <ModalWrapper
            show={addOtherFiles}
            handleClose={() => {}}
            isLandingPage
          >
            <EditInformationModal
              data={{
                otherFiles: addOtherFiles,
                data: data,
              }}
              sync={sync}
              handleClose={() => setAddOtherFiles(null)}
              setTrigger={setTrigger}
              trigger={trigger}
              isAddOtherFiles
            />
          </ModalWrapper>
        )}

        {editInformationContact && (
          <ModalWrapper
            show={editInformationContact}
            handleClose={() => {}}
            isLandingPage
          >
            <EditInformationModal
              data={{
                type: editInformationContact,
                data: data,
              }}
              sync={sync}
              handleClose={() => setEditInformationContact(null)}
              setTrigger={setTrigger}
              trigger={trigger}
              isEditInformationContact
              setHash={setHash}
              setPhoneNumberQuery={setPhoneNumber}
              setTypeOtp={setTypeOtp}
            />
          </ModalWrapper>
        )}

        {query?.get("h") && (
          <ModalWrapper show={query?.get("h")} handleClose={() => {}}>
            <OTPConfirmationModal
              setIsDone={() => {}}
              handleReset={(response) => {
                setHash(response);
                setPhoneNumber(query?.get("p"));
                setTrigger(Math?.random());
                setTypeOtp(query?.get("ty"));
              }}
              handleSubmit={() => {
                setHash("");
                setPhoneNumber("");
                setTrigger(Math?.random());
                setTypeOtp("");
              }}
              data={{
                phoneNumber: query?.get("p"),
              }}
              loading={+trigger !== +query?.get("tr")}
              isOther={query?.get("ty")}
            />
          </ModalWrapper>
        )}

        {editOtherFiles && (
          <ModalWrapper
            show={editOtherFiles}
            handleClose={() => {}}
            isLandingPage
          >
            <EditInformationModal
              data={{
                otherFiles: editOtherFiles?.file,
                data: data,
                id: editOtherFiles?.data?.id,
              }}
              sync={sync}
              handleClose={() => setEditOtherFiles(null)}
              setTrigger={setTrigger}
              trigger={trigger}
              isEditOtherFiles
            />
          </ModalWrapper>
        )}
        <HomeFooter />
      </div>
    </>
  );
}
