import Images from "@Theme/Images";
import Styles from "./styles.module.scss";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import InputField from "@Molecule/InputField";
import InputDateField from "@Molecule/InputDateField";

import Dropdown from "@Atom/DropDown";
import Icon from "@Atom/Icon";
// import InputYearField from "@Molecule/InputYearField";
import InputMonth from "@Atom/InputMonth";
// import Checkbox from "@Atom/Checkbox";
import CheckboxJobPosting from "@Atom/CheckboxJobPosting";
import InputYear from "@Atom/InputYear";
import { useDropzone } from "react-dropzone";
import UploadBox from "@Atom/UploadBox";
import { useLocation, useNavigate } from "react-router-dom";
import { setupProfile } from "@Services/home";
import moment from "moment";
import Spinner from "@Atom/Spinner";
import { handleSubmit as handleSubmitLinkJobPost } from "@Helpers/getLinkJobPosting";
import useWindowSize from "@Hooks/useWindowSize";
const PDFJS = require("pdfjs-dist/webpack");

export default function UpdateProfileUser({ data, initialData }) {
  // console.log(initialData);
  const optionsExperiences = useMemo(() => {
    return [
      {
        id: 1,
        name: "Saya memiliki pengalaman bekerja",
        img: Images.BAG,
      },
      {
        id: 2,
        name: "Saya seorang fresh graduate",
        img: Images.GRADUATE,
      },
    ];
  });

  const navigate = useNavigate();
  // console.log(state, "test");

  const targetElement = useRef();
  const scrollingTop = (event) => {
    const elmnt = targetElement;
    elmnt.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "start",
    });
  };

  const stepDefault = sessionStorage?.getItem("stepUser") || 1;
  const [step, setstep] = useState(+stepDefault || 1);

  const defaultStepOneForm = JSON.parse(
    sessionStorage?.getItem(`stepOneFormUser`)
  );

  const [formStepOne, setFormStepOne] = useState({
    name: defaultStepOneForm?.name || initialData?.name || "",
    nik: defaultStepOneForm?.nik || initialData?.idNumber || "",
    dateOfBirth:
      defaultStepOneForm?.dateOfBirth || initialData?.dateOfBirth || "",
  });

  useEffect(() => {
    setFormStepOne({
      name: defaultStepOneForm?.name || initialData?.name || "",
      nik: defaultStepOneForm?.nik || initialData?.idNumber || "",
      dateOfBirth:
        defaultStepOneForm?.dateOfBirth || initialData?.dateOfBirth || "",
    });
  }, [initialData]);

  const defaultStepTwoForm = JSON.parse(
    sessionStorage?.getItem(`stepTwoFormUser`)
  );

  const [formStepTwo, setFormStepTwo] = useState({
    gender: defaultStepTwoForm?.gender || "",
    educationLevel: defaultStepTwoForm?.educationLevel || "",
    englishLevel: defaultStepTwoForm?.englishLevel || "",
    addressDomicilie: defaultStepTwoForm?.addressDomicilie || "",
    address: defaultStepTwoForm?.address || "",
  });

  const defaultStepThreeForm =
    sessionStorage?.getItem("stepThreeFormUser") || "";

  const [formStepThree, setFormStepThree] = useState(
    defaultStepThreeForm || ""
  );

  const defaultStepFourForm = JSON.parse(
    sessionStorage?.getItem(`stepFourFormUser`)
  );

  const [formStepFour, setFormStepFour] = useState(
    defaultStepFourForm?.length > 0
      ? defaultStepFourForm
      : [
          {
            id: 1,
            position: "",
            company: "",
            startMonth: "",
            startYear: "",
            endMonth: "",
            endYear: "",
            isActive: "",
            desc: "",
          },
        ]
  );

  const [files, setFiles] = useState(null);
  const [formStepFive, setFormStepFive] = useState(false);

  const [errorFiles, setErrorFiles] = useState("");

  const { getRootProps, open } = useDropzone({
    onDrop: (acceptedFiles) => {
      if (acceptedFiles?.length > 0) {
        const file = acceptedFiles[0];

        if (file?.type === "application/pdf" || file.name.endsWith(".pdf")) {
          setFiles(acceptedFiles[0]);
          setFormStepFive(false);
          setErrorFiles("");
        } else {
          setErrorFiles("File wajib PDF");
          setFiles(null);
        }
      } else {
        setErrorFiles("File wajib PDF");
        setFiles(null);
      }
    },

    accept: {
      "application/pdf": [".pdf"],
      // "image/jpeg": [],
      // "image/jpg": [],
      // "image/png": [],
    },
    multiple: false,
    noClick: true,
  });

  const [previewTemp, setPreviewTemp] = useState(null);

  const loadImageFromPDF = useCallback(async (url) => {
    // setUploading(true);
    var pdf = await PDFJS.getDocument({ url: url }).promise;
    const canvas = document.createElement("canvas");
    const images = [];
    for (let i = 0; i < pdf.numPages; i++) {
      const page = await pdf.getPage(i + 1);
      const viewport = page.getViewport({ scale: 1 });
      const context = canvas.getContext("2d");
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      await page.render({ canvasContext: context, viewport: viewport }).promise;
      images.push(canvas.toDataURL());
    }
    canvas.remove();
    setPreviewTemp(images[0]);
    // handleChangeForm("preview", images[0]);
    // setUploading(false);
  }, []);

  useEffect(() => {
    if (files && files instanceof File) {
      const objectUrl = URL.createObjectURL(files);
      loadImageFromPDF(objectUrl);
    }
  }, [loadImageFromPDF, files]);

  const defaultState =
    JSON.parse(sessionStorage?.getItem(`stateJobPostLinkLogin`)) || null;

  const [state, setState] = useState(defaultState || null);

  useEffect(() => {
    sessionStorage?.setItem("stepUser", step || 1);
    sessionStorage?.setItem("stepThreeFormUser", formStepThree || "");
    sessionStorage?.setItem("stepOneFormUser", JSON.stringify(formStepOne));
    sessionStorage?.setItem("stepTwoFormUser", JSON.stringify(formStepTwo));
    sessionStorage?.setItem("stepFourFormUser", JSON.stringify(formStepFour));
  }, [step, formStepOne, formStepTwo, formStepThree, formStepFour]);

  const [validate, setValidate] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [isLoadingLinkJobPost, setIsLoadingLinkJobPost] = useState(null);
  const [errorLinkJobPost, setErrorLinkJobPost] = useState(null);
  const { width } = useWindowSize();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (step === 1) {
        if (
          !formStepOne?.name ||
          !formStepOne?.nik ||
          !formStepOne?.dateOfBirth ||
          (formStepOne?.nik && formStepOne?.nik?.length !== 16)
        ) {
          setValidate(true);
          scrollingTop();
        } else {
          setValidate(false);
          setstep(2);
        }
      } else if (step === 2) {
        if (
          !formStepTwo?.gender ||
          !formStepTwo?.educationLevel ||
          !formStepTwo?.englishLevel ||
          !formStepTwo?.addressDomicilie ||
          !formStepTwo?.address
        ) {
          setValidate(true);
          scrollingTop();
        } else {
          setValidate(false);
          setstep(3);
        }
      } else if (step === 3) {
        if (!formStepThree) {
          setValidate(true);
          scrollingTop();
        } else {
          setValidate(false);
          if (formStepThree === "Saya memiliki pengalaman bekerja") {
            setstep(4);
          } else {
            setstep(5);
          }
        }
      } else if (step === 4) {
        let isComplete = false;

        for (let index = 0; index < formStepFour?.length; index++) {
          const element = formStepFour[index];

          if (
            !element?.startMonth ||
            !element?.startYear ||
            (!element?.isActive && !element?.endMonth) ||
            (!element?.isActive && !element?.endYear) ||
            !element?.position ||
            !element?.desc ||
            !element?.company
          ) {
            isComplete = true; // Tandai bahwa form tidak lengkap

            break; // Hentikan iterasi
          }
        }

        setValidate(isComplete);

        if (!isComplete) {
          setstep(5);
        } else {
          scrollingTop();
        }
      } else if (step === 5) {
        if (!formStepFive && !files) {
          setValidate(true);
          scrollingTop();
        } else {
          setIsLoading(true);
          const formData = new FormData();

          formData?.append("name", formStepOne?.name || "");
          formData?.append("idNumber", formStepOne?.nik || "");
          formData?.append(
            "dateOfBirth",
            formStepOne?.dateOfBirth
              ? moment(new Date(formStepOne?.dateOfBirth)).format("YYYY-MM-DD")
              : ""
          );

          formData?.append("gender", formStepTwo?.gender || "");
          formData?.append("educationLevel", formStepTwo?.educationLevel || "");
          formData?.append("englishLevel", formStepTwo?.englishLevel || "");
          formData?.append("domicile", formStepTwo?.addressDomicilie || "");
          formData?.append("address", formStepTwo?.address || "");

          if (formStepThree === "Saya memiliki pengalaman bekerja") {
            for (let index = 0; index < formStepFour?.length; index++) {
              const element = formStepFour[index];
              formData?.append(
                `workExperience[${index}][jobTitle]`,
                element?.position || ""
              );
              formData?.append(
                `workExperience[${index}][companyName]`,
                element?.company || ""
              );
              formData?.append(
                `workExperience[${index}][startMonth]`,
                element?.startMonth || ""
              );
              formData?.append(
                `workExperience[${index}][startYear]`,
                element?.startYear || ""
              );

              if (!element?.isActive) {
                formData?.append(
                  `workExperience[${index}][endYear]`,
                  element?.endYear || ""
                );
                formData?.append(
                  `workExperience[${index}][endMonth]`,
                  element?.endMonth || ""
                );
              } else {
                formData?.append(`workExperience[${index}][endYear]`, "");
                formData?.append(`workExperience[${index}][endMonth]`, "");
              }
              formData?.append(
                `workExperience[${index}][isEmployed]`,
                element?.isActive ? "YES" : ""
              );
              formData?.append(
                `workExperience[${index}][description]`,
                element?.desc || ""
              );
            }
            // formData?.append("address", formStepTwo?.address || "");
          }

          if (!formStepFive) {
            formData?.append("CV", files || "");
          }
          const { response } = await setupProfile(formData);
          localStorage?.setItem(
            "nameUserLanding",
            response?.candidateAccount?.name
          );

          setValidate(false);
          setIsLoading(false);
          setError("");
          setstep(6);
        }
      } else {
        if (
          state &&
          state?.data &&
          localStorage?.getItem("accessTokenUserLanding")
        ) {
          setIsLoadingLinkJobPost(true);
          await handleSubmitLinkJobPost(
            new Event("submit", { bubbles: true, cancelable: true }),
            state?.data?.location,
            state?.data?.id,
            setIsLoadingLinkJobPost,
            setErrorLinkJobPost,
            width,
            true
          );
        }
        navigate("/");
        sessionStorage?.removeItem("stepOneFormUser");
        sessionStorage?.removeItem("stepTwoFormUser");
        sessionStorage?.removeItem("stepThreeFormUser");
        sessionStorage?.removeItem("stepFourFormUser");
        sessionStorage?.removeItem("stepUser");
        sessionStorage?.removeItem("stateJobPostLinkLogin");
        localStorage?.setItem("dataProfile", "complete");
      }
    } catch (error) {
      setIsLoading(false);
      setError(error.response?.data?.error);
      console.log(error);
    }
  };

  // console.log(validate, "test");

  const handleClick = (option) => {
    // Reset animasi sebelum menerapkan yang baru
    setFormStepThree("");
    setTimeout(() => {
      setFormStepThree(option); // Simpan pilihan baru
    }, 10); // Beri jeda untuk reset animasi
  };

  const content = useMemo(() => {
    switch (step) {
      case 1:
        return (
          <div className={Styles.content}>
            <span>
              👋🏻Selamat datang di Kerja365.id, yuk mulai lengkapi{" "}
              <span>data diri</span> kamu!
            </span>

            {validate && (
              <div className={Styles.errorValidate}>
                <div>
                  <Icon
                    icon={"information-solid"}
                    size={20}
                    color={"#FF3E13"}
                  />
                </div>

                <div>
                  <span>Ada data yang belum diisi</span>
                  <span>
                    Silakan lengkapi data terlebih dahulu untuk dapat
                    melanjutkan
                  </span>
                </div>
              </div>
            )}

            <div className={Styles.formBox}>
              <div className={Styles.input}>
                <span>Nama</span>
                <InputField
                  errorText={`*Nama belum diisi`}
                  isError={validate && !formStepOne?.name}
                  required
                  placeholder={"Masukkan Nama"}
                  value={formStepOne?.name}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^[a-zA-Z\s.,]*$/.test(value)) {
                      setFormStepOne((prev) => {
                        return {
                          ...prev,
                          name: value?.toUpperCase(),
                        };
                      });
                    }
                  }}
                />
              </div>

              <div className={Styles.input}>
                <span>NIK</span>
                <InputField
                  required
                  errorText={
                    validate && formStepOne?.nik?.length !== 16
                      ? "*NIK harus 16 digit"
                      : `*NIK belum diisi`
                  }
                  isError={
                    (validate && !formStepOne?.nik) ||
                    (validate && formStepOne?.nik?.length !== 16)
                  }
                  placeholder={"Masukkan NIK"}
                  value={formStepOne?.nik}
                  onChange={(e) => {
                    const value = e.target.value;
                    const re = /^[0-9\b]+$/;
                    if (re.test(value) || value === "") {
                      if (value?.length < 17) {
                        setFormStepOne((prev) => {
                          return {
                            ...prev,
                            nik: value,
                          };
                        });
                      }
                    }
                  }}
                />
              </div>

              <div className={Styles.input}>
                <span>Tanggal Lahir</span>
                <InputDateField
                  errorText={`*Tanggal lahir belum diisi`}
                  isError={validate && !formStepOne?.dateOfBirth}
                  placeholder={"Pilih tanggal lahir"}
                  value={formStepOne?.dateOfBirth}
                  setValue={(value) => {
                    setFormStepOne((prev) => {
                      return {
                        ...prev,
                        dateOfBirth: value,
                      };
                    });
                  }}
                />
              </div>
            </div>

            <div className={Styles.buttonWrapper}>
              <div>
                <button disabled>Kembali</button>
                <button onClick={handleSubmit}>Lanjut</button>
              </div>
            </div>
          </div>
        );

      case 2:
        return (
          <div className={Styles.content}>
            <span>
              Lebih detail lagi mengenai <span>kamu</span>
            </span>

            {validate && (
              <div className={Styles.errorValidate}>
                <div>
                  <Icon
                    icon={"information-solid"}
                    size={20}
                    color={"#FF3E13"}
                  />
                </div>

                <div>
                  <span>Ada data yang belum diisi</span>
                  <span>
                    Silakan lengkapi data terlebih dahulu untuk dapat
                    melanjutkan
                  </span>
                </div>
              </div>
            )}

            <div className={Styles.formBox}>
              <div className={Styles.input}>
                <span>Jenis Kelamin</span>
                <Dropdown
                  isError={validate && !formStepTwo.gender}
                  errorText="Jenis kelamin belum dipilih"
                  placeholder={"Pilih jenis kelamin"}
                  options={
                    data?.length > 0
                      ? data
                          ?.filter((e) => e?.type === "Gender")
                          ?.map((item) => {
                            return {
                              id: item?.id,
                              name: item?.value,
                            };
                          }) || []
                      : []
                  }
                  value={formStepTwo.gender}
                  setValue={(e) =>
                    setFormStepTwo((prev) => {
                      return {
                        ...prev,
                        gender: e,
                      };
                    })
                  }
                  autoClose

                  //  iconColor={iconColor}
                />
              </div>

              <div className={Styles.input}>
                <span>Pendidikan Terakhir</span>
                <Dropdown
                  isError={validate && !formStepTwo.educationLevel}
                  errorText="Pendidikan belum dipilih"
                  placeholder={"Pilih pendidikan terakhir"}
                  options={
                    data?.length > 0
                      ? data
                          ?.filter((e) => e?.type === "Education")
                          ?.map((item) => {
                            return {
                              id: item?.id,
                              name: item?.value,
                            };
                          }) || []
                      : []
                  }
                  value={formStepTwo.educationLevel}
                  setValue={(e) =>
                    setFormStepTwo((prev) => {
                      return {
                        ...prev,
                        educationLevel: e,
                      };
                    })
                  }
                  autoClose

                  //  iconColor={iconColor}
                />
              </div>

              <div className={Styles.input}>
                <span>Kemampuan Bahasa Inggris</span>
                <Dropdown
                  isError={validate && !formStepTwo.englishLevel}
                  errorText="Kemampuan bahasa inggris belum dipilih"
                  placeholder={"Pilih kemampuan bahasa inggris"}
                  options={
                    data?.length > 0
                      ? data
                          ?.filter((e) => e?.type === "English Level")
                          ?.map((item) => {
                            return {
                              id: item?.id,
                              name: item?.value,
                            };
                          }) || []
                      : []
                  }
                  value={formStepTwo.englishLevel}
                  setValue={(e) =>
                    setFormStepTwo((prev) => {
                      return {
                        ...prev,
                        englishLevel: e,
                      };
                    })
                  }
                  autoClose

                  //  iconColor={iconColor}
                />
              </div>

              <div className={Styles.input}>
                <span>Alamat Domisili (Tempat Tinggal Sekarang)</span>
                <InputField
                  isError={validate && !formStepTwo.addressDomicilie}
                  errorText="Alamat belum dipilih"
                  type={"address"}
                  // errorText={`*Nama belum diisi`}
                  // isError={validate && !formStepOne?.name}
                  required
                  placeholder={"Masukkan alamat domisili"}
                  value={formStepTwo?.addressDomicilie}
                  onChange={(e) => {
                    const value = e.target.value;
                    setFormStepTwo((prev) => {
                      return {
                        ...prev,
                        addressDomicilie: value?.toUpperCase(),
                      };
                    });
                  }}
                />
              </div>
              <div className={Styles.input}>
                <span>Alamat Sesuai KTP</span>
                <InputField
                  isError={validate && !formStepTwo.address}
                  errorText="Alamat belum dipilih"
                  type={"address"}
                  // errorText={`*Nama belum diisi`}
                  // isError={validate && !formStepOne?.name}
                  required
                  placeholder={"Masukkan alamat sesuai KTP"}
                  value={formStepTwo?.address}
                  onChange={(e) => {
                    const value = e.target.value;
                    setFormStepTwo((prev) => {
                      return {
                        ...prev,
                        address: value?.toUpperCase(),
                      };
                    });
                  }}
                />
              </div>
            </div>

            <div className={Styles.buttonWrapper}>
              <div>
                <button
                  onClick={() => {
                    setstep(1);
                  }}
                >
                  Kembali
                </button>
                <button onClick={handleSubmit}>Lanjut</button>
              </div>
            </div>
          </div>
        );

      case 3:
        return (
          <div className={Styles.content}>
            <span>
              Apakah kamu memiliki pengalaman <span>bekerja</span>?
            </span>

            {validate && (
              <div className={Styles.errorValidate}>
                <div>
                  <Icon
                    icon={"information-solid"}
                    size={20}
                    color={"#FF3E13"}
                  />
                </div>

                <div>
                  <span>Ada data yang belum diisi</span>
                  <span>
                    Silakan lengkapi data terlebih dahulu untuk dapat
                    melanjutkan
                  </span>
                </div>
              </div>
            )}

            <div className={Styles.selectBox}>
              {optionsExperiences?.map((el) => {
                return (
                  <div
                    key={el?.id}
                    className={`${
                      formStepThree === el?.name && Styles.active
                    } ${validate && !formStepThree && Styles.error}`}
                    onClick={() => {
                      if (formStepThree !== el?.name) {
                        if (el?.name === "Saya seorang fresh graduate") {
                          setFormStepFour([
                            {
                              id: 1,
                              position: "",
                              company: "",
                              startMonth: "",
                              startYear: "",
                              endMonth: "",
                              endYear: "",
                              isActive: "",
                              desc: "",
                            },
                          ]);
                        }
                        handleClick(el?.name);
                      }
                    }}
                  >
                    <img
                      src={el?.img}
                      alt=""
                      className={`${
                        formStepThree === el?.name && Styles.rotate
                      }`}
                    />
                    <span>{el?.name}</span>
                  </div>
                );
              })}
            </div>

            <div className={Styles.buttonWrapper}>
              <div>
                <button
                  onClick={() => {
                    setstep(2);
                  }}
                >
                  Kembali
                </button>
                <button onClick={handleSubmit}>Lanjut</button>
              </div>
            </div>
          </div>
        );

      case 4:
        return (
          <div className={Styles.content}>
            <span>
              Tuliskan pengalaman <span>kerja</span> kamu disini.
            </span>

            {validate && (
              <div className={Styles.errorValidate}>
                <div>
                  <Icon
                    icon={"information-solid"}
                    size={20}
                    color={"#FF3E13"}
                  />
                </div>

                <div>
                  <span>Ada data yang belum diisi</span>
                  <span>
                    Silakan lengkapi data terlebih dahulu untuk dapat
                    melanjutkan
                  </span>
                </div>
              </div>
            )}

            <div className={Styles.experiencesBox}>
              {formStepFour?.map((el, idx) => {
                return (
                  <div key={idx} className={Styles.box}>
                    <div className={Styles.title}>
                      <span>Pengalaman kerja {idx + 1}</span>
                      {formStepFour?.length > 1 && (
                        <Icon
                          icon={"delete"}
                          color={"#FFFFFF"}
                          size={20}
                          className={Styles.icon}
                          onClick={() => {
                            const updateValue = formStepFour?.filter(
                              (item) => item?.id !== el?.id
                            );

                            setFormStepFour(updateValue);
                          }}
                        />
                      )}
                    </div>

                    <div className={Styles.form}>
                      <div className={Styles.input}>
                        <span>
                          Posisi<span>*</span>
                        </span>
                        <InputField
                          required
                          errorText={"*Posisi belum diisi"}
                          isError={validate && !el?.position}
                          placeholder={"Contoh: Security"}
                          value={el?.position}
                          onChange={(e) => {
                            const value = e.target.value;
                            const updateValue = formStepFour?.map((item) => {
                              if (item?.id === el?.id) {
                                return {
                                  ...item,
                                  position: value?.toUpperCase(),
                                };
                              } else {
                                return item;
                              }
                            });
                            setFormStepFour(updateValue);
                          }}
                        />
                      </div>

                      <div className={Styles.input}>
                        <span>
                          Nama Perusahaan<span>*</span>
                        </span>
                        <InputField
                          required
                          errorText={"*Nama perusahaan belum diisi"}
                          isError={validate && !el?.company}
                          placeholder={"Contoh: Pt Maju Jaya"}
                          value={el?.company}
                          onChange={(e) => {
                            const value = e.target.value;
                            const updateValue = formStepFour?.map((item) => {
                              if (item?.id === el?.id) {
                                return {
                                  ...item,
                                  company: value?.toUpperCase(),
                                };
                              } else {
                                return item;
                              }
                            });
                            setFormStepFour(updateValue);
                          }}
                        />
                      </div>

                      <div className={Styles.input}>
                        <span>
                          Mulai<span>*</span>
                        </span>
                        <div className={Styles.dateBox}>
                          <div className={Styles.date}>
                            <InputMonth
                              errorText={"*Bulan mulai bekerja belum diisi"}
                              isError={validate && !el?.startMonth}
                              // title={"Sampai Tahun"}
                              placeholder={"Pilih Bulan"}
                              value={el?.startMonth}
                              setValue={(value) => {
                                const updateValue = formStepFour?.map(
                                  (item) => {
                                    if (item?.id === el?.id) {
                                      return {
                                        ...item,
                                        startMonth: value,
                                      };
                                    } else {
                                      return item;
                                    }
                                  }
                                );
                                setFormStepFour(updateValue);
                              }}
                              iconColor={"#0A0A0A"}
                              isArrow
                            />
                          </div>
                          <div className={Styles.date}>
                            <InputYear
                              // title={"Sampai Tahun"}
                              errorText={"*Tahun mulai bekerja belum diisi"}
                              isError={validate && !el?.startYear}
                              placeholder={"Pilih tahun"}
                              value={el?.startYear}
                              setValue={(value) => {
                                const updateValue = formStepFour?.map(
                                  (item) => {
                                    if (item?.id === el?.id) {
                                      return {
                                        ...item,
                                        startYear: value,
                                      };
                                    } else {
                                      return item;
                                    }
                                  }
                                );
                                setFormStepFour(updateValue);
                              }}
                              iconColor={"#0A0A0A"}
                              isArrow
                            />
                          </div>
                        </div>
                      </div>

                      <div className={Styles.input}>
                        <span>
                          Berakhir<span>*</span>
                        </span>
                        <div className={Styles.dateBox}>
                          <div className={Styles.date}>
                            <InputMonth
                              errorText={"*Bulan berakhir bekerja belum diisi"}
                              isError={
                                validate && !el?.endMonth && !el?.isActive
                              }
                              // title={"Sampai Tahun"}
                              placeholder={"Pilih Bulan"}
                              value={el?.endMonth}
                              setValue={(value) => {
                                const updateValue = formStepFour?.map(
                                  (item) => {
                                    if (item?.id === el?.id) {
                                      return {
                                        ...item,
                                        endMonth: value,
                                        isActive: "",
                                      };
                                    } else {
                                      return item;
                                    }
                                  }
                                );
                                setFormStepFour(updateValue);
                              }}
                              iconColor={"#0A0A0A"}
                              isArrow
                            />
                          </div>
                          <div className={Styles.date}>
                            <InputYear
                              // title={"Sampai Tahun"}
                              errorText={"*Tahun berakhir bekerja belum diisi"}
                              isError={
                                validate && !el?.endYear && !el?.isActive
                              }
                              placeholder={"Pilih tahun"}
                              value={el?.endYear}
                              setValue={(value) => {
                                const updateValue = formStepFour?.map(
                                  (item) => {
                                    if (item?.id === el?.id) {
                                      return {
                                        ...item,
                                        endYear: value,
                                        isActive: "",
                                      };
                                    } else {
                                      return item;
                                    }
                                  }
                                );
                                setFormStepFour(updateValue);
                              }}
                              iconColor={"#0A0A0A"}
                              isArrow
                            />
                          </div>
                        </div>
                        <div className={Styles.checkbox}>
                          <CheckboxJobPosting
                            checked={!el?.isActive ? false : true}
                            onChange={() => {
                              if (el?.isActive) {
                                const updateValue = formStepFour?.map(
                                  (item) => {
                                    if (item?.id === el?.id) {
                                      return {
                                        ...item,
                                        isActive: "",
                                        endMonth: "",
                                        endYear: "",
                                      };
                                    } else {
                                      return item;
                                    }
                                  }
                                );
                                setFormStepFour(updateValue);
                              } else {
                                const updateValue = formStepFour?.map(
                                  (item) => {
                                    if (item?.id === el?.id) {
                                      return {
                                        ...item,
                                        isActive: "Masih Bekerja",
                                        endMonth: "",
                                        endYear: "",
                                      };
                                    } else {
                                      return item;
                                    }
                                  }
                                );
                                setFormStepFour(updateValue);
                              }
                            }}
                          />
                          <span>Masih bekerja hingga saat ini</span>
                        </div>
                      </div>

                      <div className={Styles.input}>
                        <span>Deskripsi Pekerjaan</span>
                        <InputField
                          errorText={"*Deskripsi pekerjaan belum diisi"}
                          isError={validate && !el?.endYear}
                          type={"desc"}
                          required
                          placeholder={"Masukkan deskripsi pekerjaan "}
                          value={el?.desc}
                          onChange={(e) => {
                            const value = e.target.value;
                            const updateValue = formStepFour?.map((item) => {
                              if (item?.id === el?.id) {
                                return {
                                  ...item,
                                  desc: value?.toUpperCase(),
                                };
                              } else {
                                return item;
                              }
                            });
                            setFormStepFour(updateValue);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}

              <div className={Styles.addMore}>
                <div className={Styles.line}></div>
                <span
                  onClick={() => {
                    setFormStepFour((prev) => [
                      ...prev,
                      {
                        id: Math.random(),
                        position: "",
                        company: "",
                        startMonth: "",
                        startYear: "",
                        endMonth: "",
                        endYear: "",
                        isActive: "",
                        desc: "",
                      },
                    ]);
                  }}
                >
                  <Icon icon={"add-circle"} size={20} color={"#1571DE"} />
                  <span>Tambah Pengalaman</span>
                </span>
                <div className={Styles.line}></div>
              </div>
            </div>

            <div className={Styles.buttonWrapper}>
              <div>
                <button
                  onClick={() => {
                    setstep(3);
                  }}
                >
                  Kembali
                </button>
                <button onClick={handleSubmit}>Lanjut</button>
              </div>
            </div>
          </div>
        );

      case 5:
        return (
          <div className={`${Styles.content} ${Styles.grid}`}>
            <span>
              Unggah <span>CV</span> kamu disini.
            </span>

            {validate && (
              <div className={Styles.errorValidate}>
                <div>
                  <Icon
                    icon={"information-solid"}
                    size={20}
                    color={"#FF3E13"}
                  />
                </div>

                <div>
                  <span>Ada data yang belum diisi</span>
                  <span>
                    Silakan lengkapi data terlebih dahulu untuk dapat
                    melanjutkan
                  </span>
                </div>
              </div>
            )}

            <div className={Styles.uploadDoc} {...getRootProps()}>
              <UploadBox
                isError={(validate && !files && !formStepFive) || errorFiles}
                onClick={open}
                isPdf={true}
                isUploaded={files}
                // uploading={uploading}
                // isError={errorFileReject?.message}
                fileName={files?.name}
                // fileImage={form?.file ? URL.createObjectURL(form?.file) : ''}
                fileImage={previewTemp}
                isBlue
              />
              {errorFiles ? (
                <span>*{errorFiles}</span>
              ) : validate && !files && !formStepFive ? (
                <span>
                  *File wajib diisi atau pilih "CV saya masih belum siap untuk
                  digunakan, unggah nanti" jika CV belum siap di upload
                </span>
              ) : null}
            </div>

            <div
              className={`${Styles.noCV} ${formStepFive && Styles.active} ${
                validate && !formStepFive && !files ? Styles.error : ""
              }`}
              onClick={() => {
                if (!formStepFive) {
                  setFormStepFive(true);
                  setFiles(null);
                }
              }}
            >
              <img src={Images.DOC_X} alt="" />
              <span>
                CV saya masih belum siap untuk digunakan, unggah nanti
              </span>
            </div>

            <div className={Styles.buttonWrapper}>
              {error && <span>*{error}</span>}
              <div>
                <button
                  disabled={isLoading}
                  onClick={() => {
                    if (!isLoading) {
                      if (
                        formStepThree === "Saya memiliki pengalaman bekerja"
                      ) {
                        setstep(4);
                      } else {
                        setstep(3);
                      }
                    }
                  }}
                >
                  Kembali
                </button>
                <button disabled={isLoading} onClick={handleSubmit}>
                  {isLoading ? <Spinner /> : "Lanjut"}
                </button>
              </div>
            </div>
          </div>
        );

      case 6:
        return (
          <div className={Styles.content}>
            <span>Selamat! Akun kamu sudah berhasil dibuat</span>

            <div className={Styles.succesUpdate}>
              <img src={Images.LOGO_SUCCESS_LANDING} alt="" />
              <span>
                Data diri kamu telah berhasil dilengkapi, kembali ke home untuk
                mulai mencari lowongan pekerjaan yang kamu inginkan
              </span>
            </div>

            <div className={Styles.buttonWrapper}>
              <div>
                <button disabled={isLoadingLinkJobPost} onClick={handleSubmit}>
                  {isLoadingLinkJobPost ? <Spinner /> : "Kembali Ke Beranda"}
                </button>
              </div>
            </div>
          </div>
        );

      default:
        return (
          <div className={Styles.content}>
            <span>
              👋🏻Selamat datang di Kerja365.id, yuk mulai lengkapi{" "}
              <span>data diri</span> kamu!
            </span>

            <div className={Styles.formBox}>
              <div className={Styles.input}>
                <span>Nama</span>
                <InputField
                  errorText={`*Nama belum diisi`}
                  isError={validate && !formStepOne?.name}
                  required
                  placeholder={"Masukkan Nama"}
                  value={formStepOne?.name}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^[a-zA-Z\s.,]*$/.test(value)) {
                      setFormStepOne((prev) => {
                        return {
                          ...prev,
                          name: value?.toUpperCase(),
                        };
                      });
                    }
                  }}
                />
              </div>

              <div className={Styles.input}>
                <span>NIK</span>
                <InputField
                  required
                  errorText={
                    validate && formStepOne?.nik?.length !== 16
                      ? "*NIK harus 16 digit"
                      : `*NIK belum diisi`
                  }
                  isError={
                    (validate && !formStepOne?.nik) ||
                    (validate && formStepOne?.nik?.length !== 16)
                  }
                  placeholder={"Masukkan NIK"}
                  value={formStepOne?.nik}
                  onChange={(e) => {
                    const value = e.target.value;
                    const re = /^[0-9\b]+$/;
                    if (re.test(value) || value === "") {
                      if (value?.length < 17) {
                        setFormStepOne((prev) => {
                          return {
                            ...prev,
                            nik: value,
                          };
                        });
                      }
                    }
                  }}
                />
              </div>

              <div className={Styles.input}>
                <span>Tanggal Lahir</span>
                <InputDateField
                  errorText={`*Tanggal lahir belum diisi`}
                  isError={validate && !formStepOne?.dateOfBirth}
                  placeholder={"Pilih tanggal lahir"}
                  value={formStepOne?.dateOfBirth}
                  setValue={(value) => {
                    setFormStepOne((prev) => {
                      return {
                        ...prev,
                        dateOfBirth: value,
                      };
                    });
                  }}
                />
              </div>
            </div>

            <div className={Styles.buttonWrapper}>
              <div>
                <button disabled>Kembali</button>
                <button onClick={handleSubmit}>Lanjut</button>
              </div>
            </div>
          </div>
        );
    }
  }, [
    step,
    formStepOne,
    formStepTwo,
    handleSubmit,
    optionsExperiences,
    handleClick,
    formStepThree,
    formStepFour,
    files,
    previewTemp,
    formStepFive,
    data,
    isLoading,
    error,
    errorFiles,
    validate,
    getRootProps,
    open,
    // handleClick,
    isLoadingLinkJobPost,
  ]);

  return (
    <div className={`${Styles.container} ${step === 5 && Styles.flexStart}`}>
      <div className={Styles.autoScroll} ref={targetElement} />
      <img src={Images.ELLIPSE_231} alt="" className={Styles.images1} />

      {content}
      <img src={Images.ELLIPSE_231_2} alt="" className={Styles.images2} />
    </div>
  );
}
